/***
 *
 *   DOCS
 *   Gets title of all docs and renders table of contents
 *   Order is based on priority
 *
 **********/

import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Heading, Link } from '@chakra-ui/react';
import { getAllDocs } from 'sanity/apis/docs';

export function Docs() {
  const [links, setLinks] = useState(null);

  useEffect(() => {
    getAllDocs().then((data) => setLinks(data));
  }, []);

  return (
    <>
      <Heading
        as="h1"
        fontSize={{ base: '30px', md: '42px' }}
        mt={{ base: '100px', md: '125px' }}
        variant="h1"
      >
        OnboardList Documentation
      </Heading>
      <Heading
        as="h5"
        fontSize={{ base: '17px', md: '20px' }}
        mb="125px"
        mt="25px"
        textAlign="center"
        variant="h5"
      >
        Need help setting up OnboardList for your SaaS? Email{' '}
        <Link to="mailto:support@onboardlist.com" textDecoration="underline" isExternal>
          support@onboardlist.com
        </Link>{' '}
        or get started below!
      </Heading>
      <Box boxShadow="2xl" ml="auto" mr="auto" mb="100px" maxW="325px">
        <Heading as="h4" textAlign="center" variant="h4" pb="25px" pt="30px">
          Table of Contents
        </Heading>
        {links &&
          links.map((link) => (
            <Box key={link.docID} textAlign="center" mt="10px" pb="30px">
              <Link
                as={RouterLink}
                to={`/docs/${link.slug}`}
                fontSize="sm"
                fontWeight="semibold"
                letterSpacing="1px"
                textDecoration="underline"
              >
                {link.title}
              </Link>
            </Box>
          ))}
      </Box>
    </>
  );
}
