import { Docs } from 'views/docs/index';
import { DocArticle } from 'views/docs/article';

const docs = [
  {
    path: '/docs',
    view: Docs,
    layout: 'page',
    title: 'OnboardList Documentation',
  },
  {
    path: '/docs/:slug',
    view: DocArticle,
    layout: 'page',
    title: 'OnboardList Documentation',
  },
];
export default docs;
