import { Admin } from 'views/admin/analytics';
import { AdminAccounts } from 'views/admin/accounts';
import { AdminUsers } from 'views/admin/users';

const Admincontrol = [
  {
    path: '/admin',
    view: Admin,
    layout: 'app',
    permission: 'admin',
    title: 'Admin',
  },
  {
    path: '/admin/accounts',
    view: AdminAccounts,
    layout: 'app',
    permission: 'admin',
    title: 'Accounts',
  },
  {
    path: '/admin/users',
    view: AdminUsers,
    layout: 'app',
    permission: 'admin',
    title: 'Users',
  },
];

export default Admincontrol;
