/***
 *
 *   ANIMATE
 *   Wrapper component to animate in children
 *
 *   PROPS
 *   type: slideup, slidedown, pop (default: slideup)
 *   timeout: timeout (optional, default: 300)
 *   children: children to render
 *
 **********/

import React, { useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import './animate.scss';

export function Animate(props) {
  const type = props.type || 'slideup';
  // nodeRef fix: https://github.com/reactjs/react-transition-group/blob/1fd4a65ac45edd2aea3dec18eeb8b9c07c7eb93f/CHANGELOG.md#features
  const nodeRef = useRef(null);
  return (
    <CSSTransition
      in
      appear
      nodeRef={nodeRef}
      timeout={props.timeout || 300}
      classNames={`animate ${type}`}
    >
      <div ref={nodeRef} className={`animate ${type}`}>
        {props.children}
      </div>
    </CSSTransition>
  );
}
