import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import Moment from 'react-moment';

export function Date({ dateString }) {
  return (
    <Box fontWeight="semibold" mb="15px" textStyle="xs">
      <Text>
        <Moment format="MMMM DD, YYYY">{dateString}</Moment>
      </Text>
    </Box>
  );
}
