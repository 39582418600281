/***
 *
 *   PRICING
 *   Modify the PricePlans component with your own features
 *
 **********/

import React from 'react';
import { Row, PricePlans } from 'components/lib';

export function Pricing(props) {
  return (
    <Row color="tint" align="center" title="Simple, affordable pricing">
      <PricePlans />
    </Row>
  );
}
