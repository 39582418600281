import React from 'react';
import ClassNames from 'classnames';
import { CardElement } from '@stripe/react-stripe-js';
import { Label, Error } from 'components/lib';
import Style from './input.module.scss';

export function CardInput(props) {
  const error = props.errorMessage || 'Please provide valid credit card details';

  const css = ClassNames([Style.textbox, props.className, props.valid === false && Style.error]);

  return (
    <>
      <Label text={props.label} required={props.required} for={props.name} />

      <CardElement
        className={css}
        style={{ base: { fontFamily: 'source sans pro', fontSize: '15px' } }}
      />

      {props.valid === false && <Error message={error} />}
    </>
  );
}
