/***
 *
 *   TERMS OF SERVICE
 *
 *
 **********/

import React from 'react';
import { Box, List, Heading, ListItem, Text, Flex } from '@chakra-ui/react';

export function Terms() {
  return (
    <Flex direction="column" align="center">
      <Heading as="h1" mt={{ base: '125px', md: '150px' }} variant="h1">
        OnboardList Terms of Service
      </Heading>
      <Box
        fontSize="sm"
        maxW="768px"
        mb={{ base: '125px', md: '150px' }}
        mt="50px"
        pl="20px"
        pr="20px"
      >
        <List as="ol" styleType="decimal">
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Terms
            </Heading>
            <Text ml="20px" mt="15px">
              By accessing the website at https://www.onboardlist.io, you are agreeing to be bound
              by these terms of service, all applicable laws and regulations, and agree that you are
              responsible for compliance with any applicable local laws. If you do not agree with
              any of these terms, you are prohibited from using or accessing this site. The
              materials contained in this website are protected by applicable copyright and
              trademark law.
            </Text>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Use License
            </Heading>
            <List as="ol" ml="40px" mt="15px" styleType="lower-alpha">
              <ListItem>
                Permission is granted to temporarily download one copy of the materials (information
                or software) on OnboardList&apos;s website for personal, non-commercial transitory
                viewing only. This is the grant of a license, not a transfer of title, and under
                this license you may not:
                <List as="ol" mb="15px" ml="60px" mt="15px" styleType="lower-roman">
                  <ListItem>modify or copy the materials;</ListItem>
                  <ListItem>
                    use the materials for any commercial purpose, or for any public display
                    (commercial or non-commercial);
                  </ListItem>
                  <ListItem>
                    attempt to decompile or reverse engineer any software contained on
                    OnboardList&apos;s website;
                  </ListItem>
                  <ListItem>
                    remove any copyright or other proprietary notations from the materials; or
                  </ListItem>
                  <ListItem>
                    transfer the materials to another person or &quot;mirror&quot; the materials on
                    any other server.
                  </ListItem>
                </List>
              </ListItem>
              <ListItem>
                This license shall automatically terminate if you violate any of these restrictions
                and may be terminated by OnboardList at any time. Upon terminating your viewing of
                these materials or upon the termination of this license, you must destroy any
                downloaded materials in your possession whether in electronic or printed format.
              </ListItem>
            </List>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Disclaimer
            </Heading>
            <List as="ol" ml="40px" mt="15px" styleType="lower-alpha">
              <ListItem>
                The materials on OnboardList&apos;s website are provided on an &apos;as is&apos;
                basis. OnboardList makes no warranties, expressed or implied, and hereby disclaims
                and negates all other warranties including, without limitation, implied warranties
                or conditions of merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of rights.
              </ListItem>
              <ListItem mt="15px">
                Further, OnboardList does not warrant or make any representations concerning the
                accuracy, likely results, or reliability of the use of the materials on its website
                or otherwise relating to such materials or on any sites linked to this site.
              </ListItem>
            </List>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Limitations
            </Heading>
            <Text ml="20px" mt="15px">
              In no event shall OnboardList or its suppliers be liable for any damages (including,
              without limitation, damages for loss of data or profit, or due to business
              interruption) arising out of the use or inability to use the materials on
              OnboardList&apos;s website, even if OnboardList or a OnboardList authorized
              representative has been notified orally or in writing of the possibility of such
              damage. Because some jurisdictions do not allow limitations on implied warranties, or
              limitations of liability for consequential or incidental damages, these limitations
              may not apply to you.
            </Text>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Accuracy of Materials
            </Heading>
            <Text ml="20px" mt="15px">
              The materials appearing on OnboardList&apos;s website could include technical,
              typographical, or photographic errors. OnboardList does not warrant that any of the
              materials on its website are accurate, complete or current. OnboardList may make
              changes to the materials contained on its website at any time without notice. However
              OnboardList does not make any commitment to update the materials.
            </Text>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Links
            </Heading>
            <Text ml="20px" mt="15px">
              OnboardList has not reviewed all of the sites linked to its website and is not
              responsible for the contents of any such linked site. The inclusion of any link does
              not imply endorsement by OnboardList of the site. Use of any such linked website is at
              the user&apos;s own risk.
            </Text>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Modifications
            </Heading>
            <Text ml="20px" mt="15px">
              OnboardList may revise these terms of service for its website at any time without
              notice. By using this website you are agreeing to be bound by the then current version
              of these terms of service.
            </Text>
          </ListItem>
          <ListItem mb="15px">
            <Heading as="h5" variant="h5">
              Governing Law
            </Heading>
            <Text ml="20px" mt="15px">
              These terms and conditions are governed by and construed in accordance with the laws
              of Oregon and you irrevocably submit to the exclusive jurisdiction of the courts in
              that State or location.
            </Text>
          </ListItem>
        </List>
      </Box>
    </Flex>
  );
}
