/***
 *
 *   USERS
 *   Enables an owner to manage the users in their application
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import {
  ViewContext,
  Card,
  AccountNav,
  useAPI,
  usePermissions,
  TitleRow,
  Table,
} from 'components/lib';
import { Button, Heading } from '@chakra-ui/react';

export function Users(props) {
  const context = useContext(ViewContext);
  const permissions = usePermissions();
  const users = useAPI('/account/users');
  const [usersState, setUsersState] = useState([]);

  useEffect(() => {
    if (!users.loading) setUsersState(users.data);
  }, [users]);

  function invite(data) {
    context.modal.show({
      title: 'Add User',
      form: {
        email: {
          label: 'Email',
          type: 'email',
          required: true,
          maxLength: 64,
        },
      },
      buttonText: 'Send Invite',
      text: 'To invite more than one user, seperate the emails with a comma',
      url: '/invite',
      method: 'POST',
    });
  }

  function editUser(data) {
    context.modal.show(
      {
        title: 'Edit User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          firstName: {
            label: 'First Name',
            type: 'text',
            required: true,
            value: data.firstName,
            errorMessage: 'Please enter a first name',
            maxLength: 255,
          },
          lastName: {
            label: 'Last Name',
            type: 'text',
            required: true,
            value: data.lastName,
            errorMessage: 'Please enter a last name',
            maxLength: 64,
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
            maxLength: 64,
          },
          permission: {
            label: 'Role',
            type: 'select',
            options: permissions.data.list,
            default: data.permission,
          },
        },
        buttonText: 'Save',
        url: '/user',
        method: 'PATCH',
      },
      async (res) => {
        if (res.data) {
          const userToUpdate = await usersState[
            usersState?.findIndex((user) => user.id === data.id)
          ];
          await Object.keys(res.data.data).map((key) => (userToUpdate[key] = res.data.data[key]));
          const updatedUsers = await usersState?.filter((user) => user.id !== data.id);
          await updatedUsers.push(userToUpdate);
          await setUsersState(updatedUsers);

          await context.notification.show(
            res.data.data.first_name + ' ' + res.data.data.last_name + ' was updated',
            'success',
            true
          );
        }
      }
    );
  }

  function deleteUser(data) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete User',
        text: 'Are you sure you want to delete ' + data.firstName + ' ' + data.lastName + '?',
        url: '/user',
        method: 'DELETE',
      },
      async (res) => {
        if (res.data) {
          const updatedUsers = await usersState?.filter((user) => user.id !== data.id);
          await setUsersState(updatedUsers);
        }
        await context.notification.show(
          data.firstName + ' ' + data.lastName + ' was deleted',
          'success',
          true
        );
      }
    );
  }

  const usersData = usersState?.map((user) => {
    return {
      accountId: user.account_id,
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      dateCreated: user.date_created,
      lastActive: user.last_active,
      permission: user.permission,
      actions: {
        edit: editUser,
        email: true,
        delete: deleteUser,
      },
    };
  });

  return (
    <>
      <AccountNav />
      <TitleRow>
        <Heading color="orange" variant="h5">
          Manage Users
        </Heading>
        <Button variant="titleRowButton" onClick={invite}>
          Add User
        </Button>
      </TitleRow>

      <Card>
        <Table
          tableData={usersData}
          loading={users.loading}
          tableColumns={[
            {
              Header: 'First Name',
              accessor: 'firstName',
            },
            {
              Header: 'Last Name',
              accessor: 'lastName',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Date Created',
              accessor: 'dateCreated',
            },
            {
              Header: 'Last Login',
              accessor: 'lastActive',
            },
            {
              Header: 'Permission',
              accessor: 'permission',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
            },
          ]}
          badge={{ col: 'permission', color: 'blue' }}
        />
      </Card>
    </>
  );
}
