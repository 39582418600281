/***
 *
 *   RESET PASSWORD
 *   User can set a new password using the token
 *
 **********/

import React, { useContext } from 'react';
import { Container, Heading } from '@chakra-ui/react';
import { AuthContext, Form } from 'components/lib';

export function ResetPassword(props) {
  // context
  const context = useContext(AuthContext);

  // check for token
  const qs = props.location.search;
  const hs = props.location.hash;
  const token = qs.substring(qs.indexOf('?token=') + 7);
  const email = hs.substring(hs.indexOf('#email=') + 7);

  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        Reset Your Password
      </Heading>
      <Container mt="60px" variant="formCard">
        <Form
          data={{
            jwt: {
              type: 'hidden',
              value: token,
            },
            email: {
              label: 'Email',
              type: 'hidden',
              maxLength: 64,
              value: email,
            },
            password: {
              label: 'New Password',
              type: 'password',
              required: true,
              complexPassword: true,
              maxLength: 64,
              confirmPassword: true,
            },
          }}
          url="/user/password/reset"
          method="POST"
          buttonText="Set New Password"
          callback={(data) => {
            context.signin(data);
          }}
        />
      </Container>
    </>
  );
}
