/***
 *
 *   SEARCH
 *   Search input field
 *
 *   PROPS
 *   callback: function executed on change and submit
 *
 **********/

import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import ClassNames from 'classnames';
import Style from './search.module.scss';
import InputStyle from '../form/input/input.module.scss';

export const Search = ({ filter, setFilter, className }) => {
  const [value, setValue] = useState(filter);
  const css = ClassNames([Style.search, className]);

  const onChange = useAsyncDebounce((value) => {
    setFilter(value || '');
  }, 1000);

  return (
    <span className={css}>
      <input
        ref={(input) => {
          input && input.focus();
        }}
        className={InputStyle.textbox}
        value={value || ''}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search`}
      />
    </span>
  );
};
