/***
 *
 *   Checklist
 *   Enables an owner to manage their checklist
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import { Heading, Button, Tooltip } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Card, useAPI, ViewContext, AuthContext, History, TitleRow, Table } from 'components/lib';

export function Checklist(props) {
  const checklists = useAPI('/checklist');
  const [checklistState, setChecklistState] = useState([]);
  const context = useContext(ViewContext);
  const auth = useContext(AuthContext);

  useEffect(() => {
    if (!checklists.loading) setChecklistState(checklists.data);
  }, [checklists]);

  const addChecklist = () => {
    context.modal.show(
      {
        title: 'Add a Checklist',
        form: {
          name: {
            label: 'Checklist Name',
            type: 'text',
            value: '',
            placeholder: 'Add a name for your Checklist',
            autocomplete: 'off',
            required: true,
            maxLength: 32,
          },
          headline: {
            label: 'Headline',
            type: 'text',
            value: 'Welcome',
            autocomplete: 'off',
            required: true,
            maxLength: 32,
          },
          message: {
            label: 'Message',
            type: 'textarea',
            value: 'To get started, please complete and check off each task in the list below.',
            autocomplete: 'off',
            required: true,
            maxLength: 128,
          },
          color: {
            label: 'Color',
            type: 'text',
            value: '',
            placeholder: '#d84114',
            autocomplete: 'off',
            required: false,
            maxLength: 7,
          },
          font: {
            label: 'Font',
            type: 'text',
            value: '',
            placeholder: "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
            autocomplete: 'off',
            required: false,
            maxLength: 128,
          },
          fromName: {
            label: 'From Name',
            type: 'text',
            value: auth.user.firstName + ' ' + auth.user.lastName,
            required: true,
            maxLength: 64,
          },
          replyEmail: {
            label: 'Reply-To Email',
            type: 'email',
            value: auth.user.email,
            required: true,
            maxLength: 64,
          },
          reminderInterval: {
            label: 'Reminder Email Interval (Days)',
            type: 'number',
            value: 3,
            placeholder: '3',
            autocomplete: 'off',
            required: true,
            min: 0,
          },
        },

        buttonText: 'Add Checklist',
        text: 'Do you want to add this Checklist?',
        url: '/checklist',
        method: 'POST',
      },
      (res) => {
        if (res.data) {
          setChecklistState((prevState) => [res.data.data, ...prevState]);
        }
      }
    );
  };

  const editChecklist = (data) => {
    History.push(`/dashboard/checklist/${data.checklistId}/settings`);
  };

  const manageSteps = (data) => {
    History.push(`/dashboard/checklist/${data.checklistId}/steps`);
  };

  const deleteChecklist = (data, callback) => {
    context.modal.show(
      {
        form: {},
        title: 'Delete Checklist',
        buttonText: 'Delete Checklist',
        text: 'Are you sure you want to delete ' + data.checklistName + '? This cannot be undone.',
        url: `/checklist/${data.checklistId}`,
        method: 'DELETE',
      },
      (res) => {
        if (res.data) {
          const updatedChecklists = checklistState?.filter(
            (checklist) => checklist.id !== data.checklistId
          );
          setChecklistState(updatedChecklists);
        }
        context.notification.show(data.checklistName + ' was deleted', 'success', true);
      }
    );
  };

  const checklistsData = checklistState?.map((data) => {
    return {
      checklistName: data.checklist_name,
      checklistId: data.id,
      checklistNameShow: (
        <Tooltip
          hasArrow
          color="white"
          bg="#333440"
          label="Manage Steps"
          aria-label="Checklist Name Tooltip"
          fontFamily="Saira"
        >
          <NavLink to={`/dashboard/checklist/${data.id}/steps`}>{data.checklist_name}</NavLink>
        </Tooltip>
      ),
      checklistIdShow: (
        <CopyToClipboard
          text={data.id}
          onCopy={() => context.notification.show('Copied to Clipboard!', 'success', true)}
        >
          <button>
            <Tooltip
              hasArrow
              color="white"
              bg="#333440"
              label="Copy Id"
              aria-label="Copy Id  Tooltip"
              fontFamily="Saira"
            >
              {data.id}
            </Tooltip>
          </button>
        </CopyToClipboard>
      ),
      accountId: data.account_id,
      headline: data.headline,
      message: data.message,
      color: data.color,
      font: data.font,
      fromName: data.from_name,
      replyToEmail: data.reply_to_email,
      signups: data.signupCount || 0,
      finished: data.successesCount || 0,
      unsubscribed: data.unsubscribesCount || 0,
      reminderInterval: data.reminder_interval,
      actions: {
        custom: [
          { icon: 'list', action: manageSteps, title: 'Manage Steps' },
          { icon: 'settings', action: editChecklist, title: 'Settings' },
        ],
        delete: deleteChecklist,
      },
    };
  });

  return (
    <>
      <TitleRow>
        <Heading color="orange" variant="h5">
          Manage Checklists
        </Heading>
        <Button onClick={addChecklist} variant="titleRowButton">
          Add Checklist
        </Button>
      </TitleRow>

      <Card className="checklist-card restict-width">
        <Table
          loading={checklists.loading}
          tableData={checklistsData}
          tableColumns={[
            {
              Header: 'Checklist Name',
              accessor: 'checklistNameShow',
            },
            {
              Header: 'Checklist ID',
              accessor: 'checklistIdShow',
            },
            {
              Header: 'Signups',
              accessor: 'signups',
            },
            {
              Header: 'Finished',
              accessor: 'finished',
            },
            {
              Header: 'Unsubscribed',
              accessor: 'unsubscribed',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
            },
          ]}
        />
      </Card>
    </>
  );
}
