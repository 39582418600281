import { Profile } from 'views/account/profile';
import { Password } from 'views/account/password';
import { Billing } from 'views/account/billing';
import { Upgrade } from 'views/account/upgrade';
import { Users } from 'views/account/users';

const account = [
  {
    path: '/dashboard/account',
    view: Profile,
    layout: 'app',
    permission: 'user',
    title: 'Your Profile',
  },
  {
    path: '/dashboard/account/password',
    view: Password,
    layout: 'app',
    permission: 'user',
    title: 'Your Password',
  },
  {
    path: '/dashboard/account/billing',
    view: Billing,
    layout: 'app',
    permission: 'owner',
    title: 'Billing',
  },
  {
    path: '/dashboard/account/upgrade',
    view: Upgrade,
    layout: 'app',
    permission: 'user',
    title: 'Upgrade',
  },
  {
    path: '/dashboard/account/users',
    view: Users,
    layout: 'app',
    permission: 'owner',
    title: 'Users',
  },
];
export default account;
