import { Checklist } from 'views/checklist/checklist';
import { Steps } from 'views/checklist/manageChecklist/steps';
import { EditStep } from 'views/checklist/manageStep/editStep';
import { AddStep } from 'views/checklist/manageStep/addStep';
import { Settings } from 'views/checklist/manageChecklist/settings';

const checklist = [
  {
    path: '/dashboard/checklists',
    view: Checklist,
    layout: 'app',
    title: 'Checklists',
    permission: 'user',
    link: '/checklists',
  },
  {
    path: '/dashboard/checklist/:checklistId/steps',
    view: Steps,
    layout: 'app',
    title: 'Checklists',
    permission: 'user',
    link: '/checklists',
  },
  {
    path: '/dashboard/checklist/:checklistId/settings',
    view: Settings,
    layout: 'app',
    title: 'Checklists',
    permission: 'user',
    link: '/checklists',
  },
  {
    path: '/dashboard/step/:checklistId/:stepId',
    view: EditStep,
    layout: 'app',
    permission: 'user',
    title: 'Edit Step',
  },
  {
    path: '/dashboard/add/:checklistId',
    view: AddStep,
    layout: 'app',
    permission: 'user',
    title: 'Create A Step',
  },
];

export default checklist;
