/***
 *
 *   HEADER
 *   Header section with title used in main application (can render children)
 *
 *   PROPS
 *   title: title of the view
 *   children: children to render (optional)
 *
 **********/

import React from 'react';
import Style from './header.module.scss';
import { NavLink } from 'react-router-dom';
import ClassNames from 'classnames';

export function Header(props) {
  const css = ClassNames([Style.header, props.className]);
  return (
    <header className={css}>
      {props.title && props.path ? (
        <NavLink to={props.path}>
          <h1>{props.title}</h1>
        </NavLink>
      ) : (
        <h1>{props.title}</h1>
      )}
      {props.children}
    </header>
  );
}
