/***
 *
 *   SERIALIZER
 *   Object contains types to be applied to Sanity's BlockContent.
 *   Types are input into BlockContent's 'serializer' prop, and then
 *   the appropriate styles/components will be applied accordingly
 *   to render content.
 *
 **********/

import React from 'react';
import { urlFor } from 'sanity/utils';
import {
  Box,
  Flex,
  Image,
  Link,
  Text,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@chakra-ui/react';
import { HighlightCode } from 'components/lib';
import 'highlight.js/styles/darcula.css';

export const serializers = {
  list: (props) => {
    const { type } = props;
    const bullet = type === 'bullet';
    if (bullet) {
      return <UnorderedList mt="15px">{props.children}</UnorderedList>;
    }
    return <OrderedList mt="15px">{props.children}</OrderedList>;
  },
  listItem: (props) => <ListItem>{props.children}</ListItem>,
  marks: {
    link: ({ mark, children }) => {
      const { href } = mark;
      return (
        <Link color="orange" fontWeight="semibold" href={href}>
          {children}
        </Link>
      );
    },
  },
  types: {
    code: ({ node: { language, code, filename } }) => {
      return (
        <HighlightCode language={language}>
          {code}
          <Box>{filename}</Box>
        </HighlightCode>
      );
    },
    image: ({ node: { asset, alt } }) => {
      return (
        <Flex direction="column" justify="center" mb="25px" mt="15px">
          <Image src={urlFor({ asset }).width(1000).height(600).url()} />
          <Text mt="10px" textAlign="center" textStyle="caption">
            {alt}
          </Text>
        </Flex>
      );
    },
    block: (props) => {
      return (
        <Box fontSize="sm" mt="15px">
          {props.children}
        </Box>
      );
    },
  },
};
