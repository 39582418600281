/***
 *
 *   PAGE LAYOUT
 *   A general page layout
 *
 **********/

import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { HomeNav, Footer } from 'components/lib';

// https://css-tricks.com/couple-takes-sticky-footer/
// Uses negative margin bottom and a element to push, so negative margin doesn't pull footer up
export function PageLayout(props) {
  return (
    <>
      <Flex
        align="center"
        direction="column"
        flex="1"
        justify="center"
        maxW="1280px"
        pl="20px"
        pr="20px"
        ml="auto"
        mr="auto"
        minHeight="100%"
        mb="-270px"
      >
        <HomeNav />
        <Box as="main" flex="1" width="100%">
          {<props.children {...props.data} />}
        </Box>
        <Box height="350px"></Box>
      </Flex>
      <Footer />
    </>
  );
}
