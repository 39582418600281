/***
 *
 *   PROFILE
 *   Update the user profile or close the account
 *
 **********/

import React, { useContext, useEffect, useState } from 'react';
import {
  AuthContext,
  ViewContext,
  Form,
  Card,
  AccountNav,
  Button,
  History,
  useAPI,
} from 'components/lib';

export function Profile(props) {
  // context
  const authContext = useContext(AuthContext);
  const viewContext = useContext(ViewContext);

  // fetch
  const user = useAPI('/user');

  // state
  const [userState, setUserState] = useState([]);

  useEffect(() => {
    if (!user.loading) setUserState(user.data);
  }, [user]);

  function closeAccount() {
    viewContext.modal.show(
      {
        title: 'Close Your Account',
        form: closeAccount,
        buttonText: 'Close Account',
        url: '/account',
        method: 'DELETE',
        text:
          'Are you sure you want to delete your account? ' +
          'You will lose all of your data and this can not be undone.',
      },
      () => {
        // destory user
        localStorage.clear();
        History.push('/signup');
      }
    );
  }

  return (
    <>
      <AccountNav />
        <Card title="Edit Your Profile Information" loading={user.loading} restrictWidth>
          {userState && (
            <Form
              buttonText="Save"
              url={authContext.user.permission === 'owner' ? '/account' : '/user'}
              method="PATCH"
              data={{
                companyName: {
                  label: 'Company Name',
                  type: authContext.user.permission === 'owner' ? 'text' : 'hidden',
                  required: true,
                  value: authContext.user.companyName,
                  errorMessage: 'Please enter your Company name',
                  maxLength: 255,
                },
                firstName: {
                  label: 'First Name',
                  type: 'text',
                  required: true,
                  value: userState?.first_name,
                  errorMessage: 'Please enter your name',
                  maxLength: 255,
                },
                lastName: {
                  label: 'Last Name',
                  type: 'text',
                  required: true,
                  value: userState?.last_name,
                  errorMessage: 'Please enter your name',
                  maxLength: 64,
                },
                email: {
                  label: 'Email address',
                  type: 'email',
                  required: true,
                  value: userState?.email,
                  errorMessage: 'Please enter your email address',
                  maxLength: 64,
                },
                newsletter: {
                  label: 'Subscribe to our Newsletter',
                  type: 'switch',
                  value: userState?.newsletter?.type === 'active' || false,
                  default: true,
                },
              }}
              callback={(res) => {
                authContext.update({
                  name: res.data.data.name,
                  companyName: res.data.data.companyName,
                });
              }}
            />
          )}

          {authContext.permission.owner && (
            <>
              <br />
              <Button textOnly action={closeAccount} text="Close Your Account" />
            </>
          )}
        </Card>
    </>
  );
}
