/***
 *
 *   DocBody
 *   Renders documentation article content from Sanity Studio, using
 *   a serializer and Sanity's BlockContent
 *
 *   PROPS
 *   body: content data received from API calls in sanity folder
 *
 **********/

import React from 'react';
import { serializers } from 'components/lib';
import BlockContent from '@sanity/block-content-to-react';

export function DocBody({ body }) {
  return <BlockContent serializers={serializers} blocks={body} />;
}
