/***
 *
 *   LOGO
 *   Replace the image in /images with your own logo
 *
 **********/

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Image, Link } from '@chakra-ui/react';
import LogoImage from './images/onboardlist-logo-white.svg';
import LogoMark from './images/onboardlist-logo.svg';

export function Logo({ white }) {
  return (
    <Link as={RouterLink} to="/">
      <Image
        alt="logo"
        display="flex"
        _hover={{ cursor: 'pointer' }}
        src={white ? LogoImage : LogoMark}
      />
    </Link>
  );
}
