import React from 'react';
import { Flex } from '@chakra-ui/react';

export function TitleRow(props) {
  return (
    <Flex
      width="100%"
      display="flex"
      alignItems={{ base: 'flex-start', md: 'center' }}
      mb="15px"
      direction={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      {props.children}
    </Flex>
  );
}
