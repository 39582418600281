import React, { useState } from 'react';
import ClassNames from 'classnames';
import { Label, Error, Icon } from 'components/lib';
import Style from './input.module.scss';

export function NumberInput(props) {
  const [error, setError] = useState(props.errorMessage || 'Please enter a number');

  function validate(e) {
    const min = props.min;
    const minNum = !isNaN(min);
    const max = props.max;
    const maxNum = !isNaN(max);
    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '') valid = false;

    // input isn't required and value is blank
    if (!props.required && value) valid = true;

    // now test for a valid number
    if (isNaN(value)) {
      valid = false;
      setError('Please enter a valid number');
    }

    // // check for min max
    if (minNum && maxNum) {
      if (value >= min && value <= max) {
        valid = true;
      } else {
        valid = false;
        setError('Please enter a number between ' + min + ' and ' + max);
      }
    } else if (minNum) {
      if (value > min) {
        valid = true;
      } else {
        valid = false;
        setError('Please enter a number above ' + min);
      }
    } else if (maxNum) {
      if (value < max) {
        valid = true;
      } else {
        valid = false;
        setError('Please enter a number below ' + max);
      }
    } else {
      valid = true;
    }

    // update the parent form
    props.onChange(props.name, value, valid);
  }

  // style
  const css = ClassNames([
    Style.textbox,
    props.className,
    props.valid === false && Style.error,
    props.valid === true && Style.success,
  ]);

  return (
    <div className={Style.input}>
      <Label text={props.label} required={props.required} for={props.name} />

      <input
        type="number"
        id={props.name}
        name={props.name}
        className={css}
        value={props.value || ''}
        min={props.min}
        max={props.max}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(props.name, e.target.value, undefined)}
        onBlur={(e) => validate(e)}
      />

      {props.valid === false && <Error message={error} />}

      {props.valid === true && (
        <Icon image="check" color="#8CC57D" className={Style.successIcon} size={16} />
      )}
    </div>
  );
}
