/***
 *
 *   CARD
 *   Universal container for grouping UI components together
 *
 *   PROPS
 *   title: title string (optional)
 *   loading: boolean to toggle the loading animation (optional)
 *   shadow: apply a box shadow
 *   center: center align the card
 *   restrictWidth: restrict the width of the card
 *
 **********/

import React from 'react';
import { Spinner } from '@chakra-ui/react';
import ClassNames from 'classnames';
import Style from './card.module.scss';

export function Card(props) {
  const css = ClassNames([
    Style.card,
    props.className,
    props.loading && Style.loading,
    props.shadow && Style.shadow,
    props.center && Style.center,
    props.noPadding && Style.noPadding,
    props.restrictWidth && Style.restrictWidth,
  ]);

  return (
    <section className={css}>
      {props.title && (
        <header>
          <h2>{props.title}</h2>
        </header>
      )}

      {props.loading ? (
        <div className={Style.loading}>
          <Spinner size="xl" color={'#ec4815'} />
        </div>
      ) : (
        props.children
      )}
    </section>
  );
}
