/***
 *
 *   DOC ARTICLE
 *   Renders doc article according to slug
 *
 **********/

import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { getDocsBySlug, getAllDocs } from 'sanity/apis/docs';
import { DocBody } from 'components/lib';
import { Button, Container, Flex, Heading, Text } from '@chakra-ui/react';

export function DocArticle() {
  const { slug } = useParams();
  const [articleData, setArticleData] = useState(null);
  const [articles, setArticles] = useState([]);
  const [pageNumber, setPageNumber] = useState(0);
  // gets index of current article
  const idx = articles?.map((article) => article.slug).indexOf(slug);

  useEffect(() => {
    getDocsBySlug(slug).then((data) => setArticleData(data));
    getAllDocs().then((data) => setArticles(data));
    if (idx === articles.length - 1) {
      // return back to first page
      setPageNumber(0);
    } else {
      // gets index of next article and then is applied to 'Next Article' button below
      setPageNumber(idx + 1);
    }
  }, [slug, idx, articles.length]);

  if (!articleData)
    return (
      <Text
        fontSize={{ base: 'xl', md: '2xl' }}
        mt={{ base: '200px', md: '300px' }}
        textAlign="center"
      >
        Loading...
      </Text>
    );

  return (
    <>
      <Heading
        as="h1"
        fontSize={{ base: '30px', md: '42px' }}
        mt={{ base: '125px', md: '150px' }}
        variant="h1"
      >
        OnboardList Documentation
      </Heading>
      <Container as="section" variant="article" pb="75px">
        <Heading as="h5" variant="articleTitle" mb="50px">
          {articleData.title}
        </Heading>
        <DocBody body={articleData.body} />
      </Container>
      <Flex align="center" direction={{ base: 'column', md: 'row' }} mb="125px" justify="center">
        <Button
          as={RouterLink}
          to="/docs"
          mr={{ base: '0px', md: '20px' }}
          width="200px"
          variant="seafoam"
        >
          Back to Contents
        </Button>
        <Button
          as={RouterLink}
          to={`/docs/${articles[pageNumber]?.slug}`}
          ml={{ base: '0px', md: '20px' }}
          mt={{ base: '30px', md: '0px' }}
          width="200px"
          variant="orange"
        >
          Next Article
        </Button>
      </Flex>
    </>
  );
}
