/***
 *
 *   HOVER NAV
 *   Reveals a nav when the user hovers over a hotspot
 *   Items are rendered as children
 *
 *   PROPS
 *   label: the hotspot text
 *
 **********/

import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Icon, Animate } from 'components/lib';

export function HoverNav(props) {
  // state
  const [open, setOpen] = useState(false);

  return (
    <Flex
      height="100%"
      mr={{ base: '10px', sm: '40px' }}
      onMouseEnter={(e) => setOpen(true)}
      onMouseLeave={(e) => setOpen(false)}
      onClick={(e) => setOpen(!open)}
      position="relative"
      align="center"
      display={{ base: 'none', md: 'inherit' }}
    >
      {props.icon && (
        <Box mr={{ base: '5px', sm: '15px' }}>
          <Icon image={props.icon} size={15} />
        </Box>
      )}
      {props.label}
      {open && (
        <Animate type="slidedown" timeout={50}>
          <Box
            as="nav"
            position="absolute"
            overflowY="hidden"
            top="60px"
            right="5px"
            boxShadow="0px 5px 5px lightGray"
            borderRadius="5px"
            bgColor="white"
            padding="15px"
            _hover={{ cursor: 'pointer' }}
          >
            {props.children}
          </Box>
        </Animate>
      )}
    </Flex>
  );
}
