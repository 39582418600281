/***
 *
 *   ADMIN LAYOUT
 *   The admin layout containing the navigation
 *   and header (title, secondary nav)
 *
 *   PROPS
 *   title: title of the view
 *
 **********/

import React, { useContext } from 'react';
import ClassNames from 'classnames';
import { AuthContext, AppNav, Header, HoverNav, Button } from 'components/lib';
import Style from '../app/app.module.scss';

export function AdminLayout(props) {
  // context
  const context = useContext(AuthContext);

  // style
  const css = ClassNames([Style.app, 'with-sidebar']);

  return (
    <>
      <AppNav
        type="popup"
        items={[
          { label: 'Dashboard', link: '/admin' },
          { label: 'Accounts', link: '/admin/accounts' },
          { label: 'Users', link: '/admin/users' },
        ]}
      />

      <main className={css}>
        <Header title={props.title}>
          <HoverNav
            label={context.user.firstName + ' ' + context.user.lastName || ' '}
            align="right"
            dark
          >
            <Button text="Signout" action={context.signout} />
          </HoverNav>
        </Header>

        {<props.children {...props.data} />}
      </main>
    </>
  );
}
