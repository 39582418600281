/***
 *
 *   PASSWORD
 *   Update the users password
 *
 **********/

import React from 'react';
import { Card, Form, AccountNav } from 'components/lib';

export function Password(props) {
  return (
    <>
      <AccountNav />
      <Card title="Update Your Password" restrictWidth>
        <Form
          url="/user/password"
          method="PATCH"
          buttonText="Save Password"
          data={{
            oldpassword: {
              label: 'Old Password',
              type: 'password',
              required: true,
              maxLength: 64,
            },
            newpassword: {
              label: 'New Password',
              type: 'password',
              required: true,
              complexPassword: true,
              maxLength: 64,
            },
          }}
        />
      </Card>
    </>
  );
}
