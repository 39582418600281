import 'react-app-polyfill/ie9';
import { ChakraProvider } from '@chakra-ui/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import theme from './theme';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/app';

library.add(faBars, faTimes);

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider resetCSS theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
