import { Exclusions } from '../views/exclusions/exclusions';

const exclusions = [
  {
    path: '/dashboard/exclusions',
    view: Exclusions,
    layout: 'app',
    title: 'Exclusions',
    permission: 'user',
  },
];

export default exclusions;
