import { useMemo } from 'react';
import { useTable, usePagination } from 'react-table';
import { Spinner } from '@chakra-ui/react';
import ClassNames from 'classnames';
import Moment from 'react-moment';
import { Button, Flex, Select, Search, Badge } from 'components/lib';
import Style from './table.module.scss';

export function Table({
  tableData,
  tableColumns,
  loading,
  pagination,
  filter,
  setFilter,
  setPagination,
  headerData,
  badge,
}) {
  const data = useMemo(() => tableData || [], [tableData]);
  const columns = useMemo(() => tableColumns || [], [tableColumns]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: pagination?.page || 0, pageSize: pagination?.limit || 10 },
      manualPagination: true,
      pageCount: pagination?.totalPages || 0,
    },
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = tableInstance;

  // loading
  if (loading) {
    return (
      <div className={Style.loading}>
        <Spinner size="xl" color={'#ec4815'} />
      </div>
    );
  }

  // use page when using pagination or filter for react-table logic
  const pageRows = setFilter || setPagination ? page : rows;

  return (
    <>
      {
        // search filter
        setFilter && <Search filter={filter} setFilter={setFilter} />
      }

      {
        //header controls
        headerData && (
          <Flex align="flex-end">
            {headerData.map((header) => {
              return (
                <div className="page-select" key={header.label}>
                  <Select
                    label={header.label}
                    onChange={header.onChange}
                    text={header.text}
                    name={header.name}
                    options={header.options}
                    default={header.default}
                  />
                </div>
              );
            })}
          </Flex>
        )
      }

      <table {...getTableProps()} className={`${Style.table}  checklist-table`}>
        <thead>
          {
            //table header group
            headerGroups.map((headerGroup) => {
              return (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => {
                    const css = ClassNames([[column.id], column.id === 'actions' && Style.actions]);

                    return (
                      <th {...column.getHeaderProps()} className={css}>
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              );
            })
          }
        </thead>

        <tbody {...getTableBodyProps()}>
          {pageRows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {
                  //action buttons
                  row.cells.map((cell) => {
                    if (cell.column.id === 'actions') {
                      const rowData = { ...row.original };
                      return (
                        <td {...cell.getCellProps()} className={Style.actions}>
                          {cell.value?.custom?.map((action, i) => {
                            return (
                              <Button
                                title={action.title}
                                key={i}
                                loading={action.loading}
                                icon={action.icon}
                                action={() => action.action(rowData)}
                              />
                            );
                          })}
                          {cell.value.edit && (
                            <Button
                              title={'Edit'}
                              icon="edit"
                              action={() => cell.value.edit(rowData)}
                            />
                          )}
                          {cell.value.delete && (
                            <Button
                              title="Delete"
                              icon="trash"
                              action={() => cell.value.delete(rowData)}
                              disabled={cell.value.permission === 'owner' ? true : false}
                            />
                          )}
                          {cell.value.email && (
                            <a
                              href={`mailto:${rowData.email}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button title={'Send Email'} icon="mail" />
                            </a>
                          )}
                        </td>
                      );
                    } else {
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // boolean data
                            cell.value === true ? (
                              'Yes'
                            ) : cell.value === false ? (
                              'No'
                            ) : // check for date
                            /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/.test(cell.value) ? (
                              <Moment date={cell.value} format="YYYY-MM-DD" />
                            ) : badge && cell.column.id === badge.col && cell.value ? (
                              <Badge text={cell.value} color={badge.color} />
                            ) : (
                              // default render
                              cell.render('Cell')
                            )
                          }
                        </td>
                      );
                    }
                  })
                }
              </tr>
            );
          })}
        </tbody>
      </table>

      {
        // pagination controls
        setPagination && (
          <div className="pagination">
            <Button
              icon="chevrons-left"
              loading={loading}
              action={() => {
                gotoPage(0);
                setPagination((prevState) => {
                  return { ...prevState, page: 0 };
                });
              }}
              disabled={!canPreviousPage}
            />
            <Button
              icon="chevron-left"
              size="17px"
              loading={loading}
              action={() => {
                previousPage();
                setPagination((prevState) => {
                  return { ...prevState, page: pageIndex - 1 };
                });
              }}
              disabled={!canPreviousPage}
            />
            <Button
              icon="chevron-right"
              size="17px"
              loading={loading}
              action={() => {
                nextPage();
                setPagination((prevState) => {
                  return { ...prevState, page: pageIndex + 1 };
                });
              }}
              disabled={!canNextPage}
            />
            <Button
              icon="chevrons-right"
              loading={loading}
              action={() => {
                gotoPage(pageCount - 1);
                setPagination((prevState) => {
                  return { ...prevState, page: pageCount - 1 };
                });
              }}
              disabled={!canNextPage}
            />

            <div className="pageTotal">
              Page{' '}
              <strong>
                <input
                  type="number"
                  placeholder={pagination.page + 1}
                  onChange={(e) => {
                    const page = e.target.value ? Number(e.target.value) - 1 : 0;
                    gotoPage(page);
                    setPagination((prevState) => {
                      return { ...prevState, page: page };
                    });
                  }}
                />{' '}
                of {pageOptions.length}
              </strong>{' '}
            </div>
          </div>
        )
      }
    </>
  );
}
