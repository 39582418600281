import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import { useAPI } from 'components/lib';
import { Box, Flex, Button, Heading } from '@chakra-ui/react';
import Style from './viewdetails.module.scss';
import { useHistory } from 'react-router-dom';

export function ViewDetails({ computedMatch }) {
  const [reminderState, setReminder] = useState([]);
  const [email, setEmail] = useState();
  const { reminderId } = computedMatch.params;
  const reminder = useAPI(`/reminder?&page=1&limit=1&search=&id=${reminderId}`);
  const history = useHistory();

  useEffect(() => {
    setReminder(reminder.data?.remindersData[0]);
    setEmail(reminder.data?.email);
  }, [reminder.data]);

  const backButton = () => {
    history.goBack();
  };

  return (
    <Flex direction={{ base: 'column' }} bg="white" d="flex" alignItems="center">
      <Box width="100%" align="right">
        <Button width="130px" onClick={() => backButton()} m="5" variant="orange" type="button">
          Back
        </Button>
      </Box>
      <Heading>Reminder Details</Heading>
      {reminderState && (
        <>
          <Box mt="20" mb="20" as="section">
            <table className={`${Style.overview} ${Style.table}`}>
              <thead>
                <tr>
                  <th>To</th>
                  <th>Checklist</th>
                  <th>Step</th>
                  <th>Date</th>
                  <th>Opens</th>
                  <th>Clicks</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{reminderState.to}</td>
                  <td>{reminderState.checklist}</td>
                  <td>{reminderState.step}</td>
                  <td>{reminderState.date}</td>
                  <td>{reminderState.opens}</td>
                  <td>{reminderState.clicks}</td>
                </tr>
              </tbody>
            </table>
          </Box>
          <Box d="flex" justifyContent="center" mt="20" mb="20">
            <div className={Style.email} dangerouslySetInnerHTML={{ __html: email }} />
          </Box>
          <Heading>Events</Heading>
          <Box mt="20" mb="20" as="section">
            <table className={`${Style.table} ${Style.events}`}>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Platform</th>
                  <th>Client</th>
                  <th>Location</th>
                  <th>Seconds Read</th>
                  <th>Date</th>
                  <th>Link</th>
                </tr>
              </thead>
              <tbody>
                {reminderState.webhookData?.map((row) => {
                  return (
                    <tr key={row.id}>
                      <td>{row.record_type}</td>
                      <td>{row.data.Platform}</td>
                      <td>{row.data.Client?.Family}</td>
                      <td>{row.data.Geo?.Region}</td>
                      <td>{row.data.ReadSeconds}</td>
                      <td>{<Moment date={row.created_at} format="YYYY-MM-DD" />}</td>
                      <td style={{ fontSize: '14px' }}>{row.data.OriginalLink}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Box>
        </>
      )}
    </Flex>
  );
}
