/***
 *
 *  Manage Steps
 *   Enables an owner to manage the steps in a specific Checklist
 *
 **********/

import React, { useContext, useEffect, useState } from 'react';
import { Flex, Button, Heading, Spinner } from '@chakra-ui/react';
import { Card, StepsNav, useAPI, ViewContext, Table } from 'components/lib';
import { NavLink, useHistory } from 'react-router-dom';
import { apiRoot } from 'settings';

export function Steps(props) {
  const { checklistId } = props.computedMatch.params;
  const checklist = useAPI(`/checklist/${checklistId}`);
  const steps = useAPI(`/checklist/${checklistId}/steps`);
  const [loading, setLoading] = useState(false);
  const [stepList, setStepList] = useState([]);
  const [checklistState, setChecklistState] = useState([]);
  const context = useContext(ViewContext);
  const history = useHistory();

  useEffect(() => {
    let loaded = true;
    if (loaded) {
      if (!steps.loading && !checklist.loading) {
        setStepList(steps.data);
        setChecklistState(checklist.data);
      }
    }
    return () => {
      loaded = false;
    };
  }, [steps, checklist]);

  const editStep = (data) => {
    history.push(`/dashboard/step/${checklistId}/${data.stepId}`);
  };

  const deleteStep = (data, callback) => {
    const updatedChecklists = stepList?.filter((step) => step.id !== data.stepId);
    context.modal.show(
      {
        form: {
          checklistId: {
            type: 'hidden',
            value: checklistId,
          },
          stepList: {
            type: 'hidden',
            value: updatedChecklists,
          },
        },
        title: 'Delete Step',
        buttonText: 'Delete Step',
        text: 'Do you want to delete ' + data.step + '?',
        url: `/step/${data.stepId}`,
        method: 'DELETE',
      },
      (res) => {
        if (res.data) {
          updatedChecklists.map((step, index) => {
            return (step.sort_order = index + 1);
          });
          setStepList(updatedChecklists);
        }
        context.notification.show(data.step + ' was deleted', 'success', true);
      }
    );
  };

  const sendReminderTest = (data) => {
    setLoading(true);
    const user = JSON.parse(localStorage.getItem('user'));
    fetch(`${apiRoot}/reminder`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ` + user.token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stepId: data.stepId,
        email: user.email,
      }),
    }).then(() => {
      setLoading(false);
      context.notification.show(`Test email sent to ${user.email}`, 'success', true);
    });
  };

  const stepsData = stepList?.map((step) => {
    return {
      stepId: step.id,
      step: step.item,
      active: step.active,
      checked_by_default: step.checked_by_default,
      sort_order: step.sort_order,
      link: step.link,
      linkTargetBlank: step.link_target_blank,
      reminderSubject: step.reminder_subject,
      reminderText: step.reminder_text,
      reminderHtml: step.reminder_html,
      reminderCTAText: step.reminder_cta_text,
      reminderCTALink: step.reminder_cta_link,
      checklistId: step.checklist_id,
      actions: {
        custom: [
          { icon: 'edit', action: editStep, title: 'Edit Step' },
          { icon: 'mail', action: sendReminderTest, title: 'Send Test Reminder', loading: loading },
        ],
        delete: deleteStep,
      },
    };
  });

  return (
    <>
      <StepsNav checklistId={checklistId} />
      <Flex
        mt="30px"
        justify="space-between"
        align={{ base: 'flex-start', md: 'center' }}
        direction={{ base: 'column', md: 'row' }}
        mb="15px"
      >
        {checklistState?.checklist_name ? (
          <Heading variant="h5" color="orange">
            Manage Steps for {checklistState?.checklist_name}
          </Heading>
        ) : (
          <Spinner size="xl" color={'#ec4815'} />
        )}

        <Button
          as={NavLink}
          to={{
            pathname: `/dashboard/add/${checklistId}`,
            state: stepList?.length || 0,
          }}
          variant="titleRowButton"
        >
          Add Step
        </Button>
      </Flex>

      {stepList && checklistState && (
        <Card className="manage-steps">
          <Table
            tableData={stepsData}
            loading={stepList.loading}
            tableColumns={[
              {
                Header: 'Step',
                accessor: 'step',
              },
              {
                Header: 'Active',
                accessor: 'active',
              },
              {
                Header: 'Sort Order',
                accessor: 'sort_order',
              },
              {
                Header: 'Checked by default',
                accessor: 'checked_by_default',
              },
              {
                Header: 'Actions',
                accessor: 'actions',
              },
            ]}
          />
        </Card>
      )}
    </>
  );
}
