/***
 *
 *   STEPS STATS
 *   Steps are used to indicate the current point in a
 *   multi-stage process, such as filling in a long form
 *
 *
 **********/

import React from 'react';
import { useHistory } from 'react-router';

import { Grid, Button, Card, Stat, ProgressBar, useAPI } from 'components/lib';

export function Stats(props) {
  const stats = useAPI(`/checklist/${props.data.id}/stats`);
  let history = useHistory();

  const handleChecklist = () => {
    history.push('/dashboard/signups', { checklist: props.data.id });
  };

  return (
    <Card
      center={'center'}
      className="progress-cards"
      title={`${props.data.checklist_name} - Total Percent Complete`}
      loading={props.loading}
    >
      {stats?.data?.steps?.map((step, index) => {
        return (
          <ProgressBar key={step.id} label={`${step.item}`} progress={step.percentCompleted} />
        );
      })}
      <Grid className={'step-stats'} cols="4">
        <Stat
          loading={stats.loading}
          value={stats?.data?.signups}
          label="signups"
          className="step-cards"
        />
        <Stat
          loading={stats.loading}
          value={stats?.data?.successes}
          label="finished"
          className="step-cards"
        />
        <Stat
          loading={stats.loading}
          value={stats?.data?.unsubscribes}
          label="unsubscribed"
          className="step-cards"
        />
      </Grid>
      <center>
        <Button action={handleChecklist} color="teal" text="View Signups" />
      </center>
    </Card>
  );
}
