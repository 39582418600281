/***
 *
 *   useAPI hook
 *   Interact with API calls – handle errors, return loading state and data
 *
 *   PROPS
 *   url: endpoint url
 *   method: get, post, put etc.. (default: get)
 *   body: request body (optional)
 *
 **********/

import { useState, useEffect, useContext, useCallback, useRef } from 'react';
import Axios from 'axios';
import { ViewContext } from 'components/lib';
import { apiRoot } from 'settings';

export function useAPI(path, method) {
  // context & state
  const context = useRef(useContext(ViewContext));
  const [state, setState] = useState({ data: null, loading: false });

  const fetch = useCallback(
    async (source) => {
      try {
        if (!path) {
          setState({ data: null, loading: false });
          return false;
        }

        setState({ loading: true });
        const res = await Axios({
          url: apiRoot + path,
          method: method || 'get',
          cancelToken: source.token,
        });

        if (!Axios.isCancel(res)) setState({ data: res.data.data, loading: false });
      } catch (err) {
        if (!Axios.isCancel(err)) context?.current && context.current.handleError(err);
      }
    },
    [path, method, context]
  );

  useEffect(() => {
    const source = Axios.CancelToken.source();

    fetch(source);

    return () => {
      source.cancel();
    };
  }, [fetch]);

  return state;
}
