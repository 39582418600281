/***
 *
 *   ACCOUNT NAV
 *   Acount sub nav that renders different options based
 *   on user permissions. Shows billing & user admin to only account owners
 *
 **********/

import React, { useContext } from 'react';
import { Box, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import { AuthContext } from 'components/lib';
import './sub.scss';

export function AccountNav(props) {
  const context = useContext(AuthContext);

  return (
    <Box as="nav" bgColor="white" padding="20px 0px 20px 26px" m="-35px -24px 50px -25px">
      <Link as={NavLink} exact to="/dashboard/account" variant="subNavLink">
        Profile
      </Link>
      <Link as={NavLink} exact to="/dashboard/account/password" variant="subNavLink">
        Password
      </Link>

      {!context.permission?.admin && (
        <Link as={NavLink} exact to="/dashboard/account/billing" variant="subNavLink">
          Billing
        </Link>
      )}

      {context.permission?.owner && (
        <Link as={NavLink} exact to="/dashboard/account/users" variant="subNavLink">
          Users
        </Link>
      )}
    </Box>
  );
}
