import React from 'react';
import { Box, FormLabel, FormControl, Input, FormErrorMessage } from '@chakra-ui/react';
import ClassNames from 'classnames';
import { Icon } from 'components/lib';
import Style from './input.module.scss';

export function TextInput(props) {
  const error = props.errorMessage || 'Please enter a value';

  function validate(e) {
    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '') valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '') valid = true;

    if (props.required && value !== '') valid = true;

    // check if hexcolor
    if (props.hexcolor) {
      if (!/#[0-9a-f]{6}/gi.test(value)) valid = false;
    }

    // update the parent form
    props.onChange(props.name, value, valid);
  }

  // style
  const css = ClassNames([
    Style.textbox,
    props.className,
    props.valid === true && Style.success,
    props.valid === false && Style.error,
  ]);

  return (
    <FormControl isInvalid={props.valid === false} isRequired={props.required}>
      <FormLabel htmlFor={props.name} textStyle="formLabel">
        {props.label}
      </FormLabel>

      {props.type === 'textarea' ? (
        <textarea
          maxLength={props.maxLength}
          id={props.id}
          name={props.name}
          className={css}
          value={props.value}
          tabIndex={props.tabindex}
          autoComplete={props.autocomplete}
          placeholder={props.placeholder}
          onChange={(e) => props.onChange(props.name, e.target.value, undefined)}
          onBlur={(e) => validate(e)}
        ></textarea>
      ) : (
        <Input
          maxLength={props.maxLength}
          type="text"
          id={props.id}
          name={props.name}
          value={props.value || ''}
          tabIndex={props.tabindex}
          autoComplete={props.autocomplete}
          placeholder={props.placeholder}
          onChange={(e) => props.onChange(props.name, e.target.value, undefined)}
          onBlur={(e) => validate(e)}
          focusBorderColor="none"
          errorBorderColor="lightRed"
          borderLeftRadius="5px"
          borderRightRadius="5px"
          mb="20px"
          borderColor="black"
          size="lg"
          fontSize="sm"
          variant="outline"
        />
      )}

      <FormErrorMessage
        bg="lightRed"
        color="white"
        fontSize="xxs"
        p="10px 16px"
        position="relative"
        top="-27px"
        outline="1px solid #d95565"
      >
        {error}
      </FormErrorMessage>

      {props.valid === true && (
        <Box position="absolute" top="45%" right="10px">
          <Icon image="check" color="lightGreen" size={16} />
        </Box>
      )}
    </FormControl>
  );
}
