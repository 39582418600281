import client from '../client';

export const blogFields = `
    "blogID": _id,
    title,
    subtitle,
    'slug': slug.current,
    publishedAt,
    'author': author->{name, 'picture': image.asset->url},
    mainImage,
    'categories': categories[]->{title, description},
  `;

export async function getLatestBlogs() {
  const result = await client.fetch(
    `*[_type == 'post'] | order(publishedAt desc) {${blogFields}}[0...2]`
  );

  return result;
}

// offset = how many data you want to skip, limit = how many date you want to fetch
export async function getAllBlogs(params) {
  const date = params?.date ? params.date : 'desc';
  const offset = params?.offset ? params.offset : 0;
  const results = await client.fetch(
    `*[_type == "post"] | order(publishedAt ${date}) {${blogFields}}[${offset}...${offset + 6}]`
  );
  return results;
}

export async function getBlogBySlug(slug) {
  const result = await client
    .fetch(
      `*[_type == "post" && slug.current == $slug] {
      ${blogFields}
      body[]{..., "asset": asset->}
      }`,
      { slug }
    )
    .then((res) => res[0]);

  return result;
}
