import React from 'react';
import Axios from 'axios';

// components
import { Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute, AuthProvider } from './auth';
import { History, View } from 'components/lib';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

// utilities
import { ScrollToTop } from 'utils/utils';

// 404
import { NotFound } from 'views/error/404';

// settings
const { stripe, apiRoot } = require('settings');

const StripePromise = loadStripe(stripe.publishableAPIKey);

const Routes = [
  ...require('routes/account').default,
  ...require('routes/app').default,
  ...require('routes/auth').default,
  ...require('routes/website').default,
  ...require('routes/admin').default,
  ...require('routes/checklists').default,
  ...require('routes/signups').default,
  ...require('routes/blog').default,
  ...require('routes/docs').default,
  ...require('routes/exclusions').default,
  ...require('routes/reminders').default,
];

export default function App(props) {
  const user = JSON.parse(localStorage.getItem('user'));

  if (user?.token) {
    // add auth token to api header calls & log last_active
    Axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.token;
    Axios.patch(apiRoot + '/user/active', {
      last_active: new Date().toISOString().slice(0, 19).replace('T', ' '),
    });
  }

  // render the routes
  return (
    <Elements stripe={StripePromise}>
      <Router history={History}>
        <AuthProvider>
          <ScrollToTop />
          <Switch>
            {Routes.map((route) => {
              const RouteType = route.permission ? PrivateRoute : Route;

              return (
                <RouteType
                  key={route.path}
                  exact
                  path={route.path}
                  permission={route.permission}
                  render={(data) => (
                    <View
                      display={route.view}
                      layout={route.layout}
                      title={route.title}
                      data={data}
                      path={route.link}
                    />
                  )}
                />
              );
            })}

            {/* 404 */}
            <Route render={() => <View display={NotFound} layout="page" title="404 Not Found" />} />
          </Switch>
        </AuthProvider>
      </Router>
    </Elements>
  );
}
