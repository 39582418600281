/***
 *
 *   SIGN UP (user)
 *   Signup form for child users
 *
 **********/

import React, { useContext } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { Container, Heading, Link, Text } from '@chakra-ui/react';
import { AuthContext, Form } from 'components/lib';

export function SignupUser(props) {
  const context = useContext(AuthContext);
  const url = useLocation().search;
  const id = url.substring(url.indexOf('?id=') + 4, url.indexOf('&email'));
  const email = url.substring(url.indexOf('&email') + 7);

  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        Create Your Account
      </Heading>
      <Container mt="60px" variant="formCard">
        <Form
          data={{
            firstName: {
              label: 'First Name',
              value: '',
              type: 'text',
              required: true,
              errorMessage: 'Please enter your first name',
              maxLength: 255,
            },
            lastName: {
              label: 'Last Name',
              value: '',
              type: 'text',
              required: true,
              errorMessage: 'Please enter your last name',
              maxLength: 64,
            },
            email: {
              label: 'Email',
              value: email,
              type: 'email',
              required: true,
              maxLength: 64,
            },
            password: {
              label: 'Password',
              type: 'password',
              required: true,
              complexPassword: true,
              maxLength: 64,
            },
            confirm_password: {
              type: 'hidden',
              value: null,
            },
            inviteId: {
              type: 'hidden',
              value: id,
            },
          }}
          url="/user"
          method="POST"
          redirect="/dashboard"
          buttonText="Create Account"
          callback={context.signin}
        />

        <Text textStyle="formText" mt="10px">
          Already Registered?{' '}
          <Link as={RouterLink} to="/signin" color="orange">
            Log in.
          </Link>
        </Text>
      </Container>
    </>
  );
}
