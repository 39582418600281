/***
 *
 *   ADMIN ANALYTICS
 *   Manage all users signed up to your application
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import Axios from 'axios';
import { apiRoot } from 'settings';
import { ViewContext, AuthContext, Card, usePermissions, useAPI, Table } from 'components/lib';

export function AdminUsers(props) {
  const context = useContext(ViewContext);
  const authContext = useContext(AuthContext);
  const permissions = usePermissions();
  const users = useAPI('/admin/users');
  const [usersState, setUsersState] = useState([]);

  useEffect(() => {
    if (!users.loading) setUsersState(users.data);
  }, [users]);

  function editUser(data, callback) {
    // only admin can change owner permission
    if (permissions.data.list.findIndex((x) => x.value === 'owner') < 0)
      permissions.data.list.push({ value: 'owner', label: 'owner' });

    context.modal.show(
      {
        title: 'Edit User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          accountId: {
            type: 'hidden',
            value: data.accountId,
          },
          firstName: {
            label: 'First Name',
            type: 'text',
            value: data.firstName,
            requried: true,
            maxLength: 255,
          },
          lastName: {
            label: 'Last Name',
            type: 'text',
            value: data.lastName,
            requried: true,
            maxLength: 64,
          },
          email: {
            label: 'Email',
            type: 'email',
            value: data.email,
            required: true,
            maxLength: 64,
          },
          permission: {
            label: 'Permission',
            type: 'select',
            default: data.permission,
            options: permissions.data.list,
            required: true,
          },
        },
        buttonText: 'Save',
        url: '/admin/user',
        method: 'PATCH',
      },
      async (res) => {
        if (res.data) {
          const userToUpdate = await usersState[
            usersState?.findIndex((user) => user.id === data.id)
          ];
          await Object.keys(res.data.data).map((key) => (userToUpdate[key] = res.data.data[key]));
          const updatedUsers = await usersState?.filter((user) => user.id !== data.id);
          await updatedUsers.push(userToUpdate);
          await setUsersState(updatedUsers);

          await context.notification.show(
            res.data.data.first_name + ' ' + res.data.data.last_name + ' was updated',
            'success',
            true
          );
        }
      }
    );
  }

  function deleteUser(data, callback) {
    context.modal.show(
      {
        title: 'Delete User',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          accountId: {
            type: 'hidden',
            value: data.accountId,
          },
        },
        buttonText: 'Delete User',
        text: 'Are you sure you want to delete ' + data.firstName + ' ' + data.lastName + '?',
        url: '/admin/user',
        method: 'DELETE',
      },
      async (res) => {
        if (res.data) {
          const updatedUsers = await usersState?.filter((user) => user.id !== data.id);
          await setUsersState(updatedUsers);
        }
        await context.notification.show(
          data.firstName + ' ' + data.lastName + ' was deleted',
          'success',
          true
        );
      }
    );
  }

  async function impersonateUser(data) {
    const res = await Axios.post(apiRoot + '/admin/user/impersonate', {
      id: data.id,
    });
    authContext.signin(res);
  }

  const usersData = usersState?.map((user) => {
    return {
      id: user.id,
      accountId: user.account_id,
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      permission: user.permission,
      dateCreated: user.date_created,
      lastActive: user.last_active,
      actions: {
        edit: editUser,
        delete: deleteUser,
        email: true,
        custom: [{ icon: 'log-in', action: impersonateUser, title: 'Impersonate User' }],
      },
    };
  });

  return (
    <Card>
      <Table
        tableData={usersData}
        loading={users.loading}
        tableColumns={[
          {
            Header: 'First Name',
            accessor: 'firstName',
          },
          {
            Header: 'Last Name',
            accessor: 'lastName',
          },
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Date Created',
            accessor: 'dateCreated',
          },
          {
            Header: 'Last Login',
            accessor: 'lastActive',
          },
          {
            Header: 'Permission',
            accessor: 'permission',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
          },
        ]}
        badge={{ col: 'permission', color: 'blue' }}
      />
    </Card>
  );
}
