import React from 'react';
import { Line } from 'react-chartjs-2';

export function SparkLineChart(props) {
  let options = { ...props.options };

  options.scales = {
    xAxes: [{ display: false }],
    yAxes: [{ display: false }],
  };

  options.maintainAspectRatio = false;
  options.responsive = true;

  return <Line data={props.data} options={options} />;
}
