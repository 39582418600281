import { Dashboard } from 'views/dashboard/analytics';
import { Help } from 'views/dashboard/help';

const App = [
  {
    path: '/dashboard',
    view: Dashboard,
    layout: 'app',
    permission: 'user',
    title: 'Dashboard',
  },
  {
    path: '/help',
    view: Help,
    layout: 'app',
    permission: 'user',
    title: 'Get Help',
  },
];
export default App;
