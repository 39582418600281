import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const breakpoints = createBreakpoints({
  base: '0px',
  sm: '480px',
  md: '768px',
  lg: '992px',
  xl: '1280px',
});

const colors = {
  orange: '#EC4815',
  lightOrange: '#ED5627',
  seafoam: '#E6FAF8',
  darkSeafoam: '#B5F5E1',
  lightRed: '#d95565',
  lightGreen: '#8CC57D',
  lightGray: '#f3f3fa',
  gray: '#d3d3d3',
  darkGray: '#262626',
};

const fonts = {
  body: 'Saira',
  heading: 'Saira',
};

const fontSizes = {
  xxs: '13px',
  xs: '14px',
  sm: '17px',
  md: '20px',
  lg: '24px',
  xl: '30px',
  '2xl': '36px',
  '3xl': '42px',
};

const Heading = {
  variants: {
    h1: {
      color: '#EC4815',
      fontSize: '3xl',
      fontWeight: 'semibold',
      lineHeight: '50px',
      textAlign: 'center',
    },
    h2: {
      fontSize: '2xl',
      fontWeight: 'semibold',
      lineHeight: '44px',
      textAlign: 'center',
    },
    h3: {
      fontSize: 'xl',
      fontWeight: 'semibold',
    },
    h4: {
      fontSize: 'lg',
      fontWeight: 'semibold',
    },
    h5: {
      fontSize: 'md',
      fontWeight: 'semibold',
      lineHeight: '28px',
    },
    articleTitle: {
      pt: { sm: '10px', md: '50px' },
      textAlign: 'center',
      fontSize: 'md',
      fontWeight: 'semibold',
      lineHeight: '28px',
      width: '100%',
    },
    cardTitle: {
      position: 'relative',
      overflow: 'hidden',
      textAlign: 'center',
      color: 'orange',
      fontSize: 'lg',
      fontWeight: 'bold',
      lineHeight: '50px',
      pb: '20px',
      mb: '30px',
      bgColor: 'white',
      borderBottom: '1px dotted lightGray',
    },
  },
};

const textStyles = {
  caption: {
    fontSize: 'xs',
    fontWeight: 'semibold',
  },
  date: {
    fontSize: 'xs',
  },
  formLabel: {
    fontSize: 'xs',
  },
  formText: {
    fontSize: 'xs',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  errorText: {
    position: 'relative',
    top: '-3px',
    display: 'block',
    color: 'white',
    fontSize: 'xxs',
    fontWeight: 'semibold',
    mt: '10px',
    mb: '25px',
    padding: '10px 16px',
    bg: 'lightRed',
    textAlign: 'center',
  },
};

const Button = {
  baseStyle: {
    borderWidth: '1px',
    borderRadius: '5px',
    fontSize: 'md',
    fontWeight: 'semibold',
    _hover: {
      cursor: 'pointer',
      textDecoration: 'none',
    },
    width: '170px',
  },
  variants: {
    orange: {
      bg: 'orange',
      borderColor: 'orange',
      color: 'white',
      _hover: {
        bg: 'lightOrange',
        borderColor: 'lightOrange',
      },
      pb: '23px',
      pt: '23px',
    },
    orangeOutline: {
      borderColor: 'orange',
      color: 'orange',
      pb: '23px',
      pt: '23px',
    },
    seafoam: {
      bg: 'seafoam',
      borderColor: 'darkSeafoam',
      color: 'orange',
      pb: '24px',
      pt: '24px',
    },
    plain: {
      width: '100%',
      fontSize: 'xxs',
      color: 'black',
      margin: '0px',
      borderRadius: '0px',
      borderWidth: '0px',
    },
    titleRowButton: {
      bg: 'orange',
      borderColor: 'orange',
      color: 'white',
      _hover: {
        bg: 'lightOrange',
        borderColor: 'lightOrange',
      },
      pb: '20px',
      pt: '20px',
      width: '120px',
      fontSize: 'xs',
      mt: {
        base: '20px',
        md: 'initial',
      },
    },
  },
};

const Link = {
  baseStyle: {
    _hover: {
      textDecoration: 'none',
    },
  },
  variants: {
    subNavLink: {
      _activeLink: { borderBottom: '3px solid orange' },
      _focus: { outline: 'none' },
      mr: '25px',
      pb: '10px',
    },
  },
};

const Container = {
  variants: {
    formCard: {
      boxShadow: '2xl',
      maxW: '400px',
      padding: '20px',
    },
    article: {
      boxShadow: { sm: 'none', md: '2xl' },
      maxW: '992px',
      mb: { base: '50px', md: '100px' },
      mt: { base: '30px', md: '100px' },
      ml: 'auto',
      mr: 'auto',
      pb: { base: '0px', md: '30px' },
      pl: { sm: '10px', md: '50px' },
      pr: { sm: '10px', md: '50px' },
    },
    card: {
      position: 'relative',
      padding: '25px',
      zIndex: '1',
      mb: '25px',
      bg: 'white',
      borderRadius: '5px',
    },
  },
};

const theme = extendTheme({
  ...chakraTheme,
  breakpoints,
  colors,
  components: {
    Button,
    Link,
    Container,
    Heading,
  },
  fonts,
  fontSizes,
  textStyles,
});

export default theme;
