/***
 *
 *   AUTHENTICATION
 *   Auth provider to manage auth functions throughout
 *   the application. <PrivateRoute> component to
 *   protect internal application routes from unauthenticated
 *   access.
 *
 **********/
import Axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Redirect, Route } from 'react-router-dom';

// auth context
export const AuthContext = React.createContext();

const { useAPI, History } = require('components/lib');
const permissions = require('./permissions');

export function AuthProvider(props) {
  const cache = JSON.parse(localStorage.getItem('user'));
  const [user, setUser] = useState(cache);
  const subscription = useAPI(user ? '/account/subscription' : null);

  useEffect(() => {
    // update subscription status
    if (!subscription.loading) update({ subscription: subscription.data });
  }, [subscription]);

  function signin(res) {
    if (res.data) {
      localStorage.setItem('user', JSON.stringify(res.data));
      setUser(res.data);
      Axios.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.token;
      History.push(
        res.data.subscription !== 'active'
          ? '/dashboard/account/billing'
          : res.data.permission === 'admin'
          ? '/admin'
          : '/dashboard'
      );
    }
  }

  function signout() {
    localStorage.clear();
    setUser(null);
    History.push('/signin');
  }

  function update(data) {
    if (localStorage.getItem('user')) {
      let user = JSON.parse(localStorage.getItem('user'));
      for (let key in data) {
        if (typeof data[key] === 'object') {
          for (let innerKey in data[key]) {
            user[key][innerKey] = data[key][innerKey];
          }
        } else {
          user[key] = data[key];
        }
      }

      localStorage.setItem('user', JSON.stringify(user));
      setUser(user);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        user: user,
        signin: signin,
        signout: signout,
        update: update,
        permission: permissions[user?.permission],
      }}
      {...props}
    />
  );
}

// custom route object checks for an auth token before
// rendering the route – redirects if token is not present
export function PrivateRoute(props) {
  return (
    <Route
      {...props}
      render={(data) => {
        // check user
        const user = JSON.parse(localStorage.getItem('user'));

        if (user?.token) {
          if (permissions[props.permission][props.permission]) {
            // user is good
            return <props.render {...props} />;
          }
        }

        // user is not authenticated
        return <Redirect to="/signin" />;
      }}
    />
  );
}
