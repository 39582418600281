/***
 *
 *   FOOTER (homepage)
 *   Static homepage footer
 *
 **********/

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Flex, Link, Text } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

export function Footer() {
  return (
    <Flex as="footer" bg="orange" color="white" direction="column" height="270px">
      <Flex fontSize="sm" fontWeight="semibold" justify="center" mb="35px" mt="40px">
        <Link as={RouterLink} to="/blog" mr="12px">
          Blog
        </Link>
        <Link as={RouterLink} to="/docs" mr="12px">
          Docs
        </Link>
        <Link as={RouterLink} to="/signin">
          Sign In
        </Link>
      </Flex>
      <Flex justify="center">
        <Link isExternal={true} href="https://www.facebook.com/OnboardList">
          <FontAwesomeIcon color="white" icon={faFacebookF} size="lg" />
        </Link>
        <Link href="https://twitter.com/SaaSOnboarding" isExternal={true} ml="30px" mr="30px">
          <FontAwesomeIcon color="white" icon={faTwitter} size="lg" />
        </Link>
        <Link href="https://www.linkedin.com/company/onboardlist" isExternal={true}>
          <FontAwesomeIcon color="white" icon={faLinkedinIn} size="lg" />
        </Link>
      </Flex>
      <Flex fontSize="xs" fontWeight="normal" justify="center" mt="35px">
        <Link as={RouterLink} to="/privacy-policy" mr="12px" textAlign="center">
          Privacy Policy
        </Link>
        <Link as={RouterLink} to="/terms-of-service" ml="12px" textAlign="center">
          Terms of Service
        </Link>
      </Flex>
      <Text fontSize="xs" fontWeight="normal" pb="50px" pt="15px" textAlign="center">
        {`© OnboardList`}
      </Text>
    </Flex>
  );
}
