/***
 *
 *   UNSUBSCRIBE
 *   Unsubscribe from link in reminder emails
 *
 **********/

import React, { useContext } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { Container, Heading, Text } from '@chakra-ui/react';
import { Form, ViewContext } from 'components/lib';

export function Unsubscribe() {
  const context = useContext(ViewContext);
  const query = new URLSearchParams(useLocation().search);
  const signupId = query.get('signupId');
  const email = query.get('email');
  const company = query.get('company');
  const history = useHistory();

  if (signupId !== 'null' && signupId && email && company) {
    return (
      <>
        <Heading as="h1" mt="125px" variant="h1">
          Unsubscribe
        </Heading>
        <Container mt="60px" variant="formCard">
          <Heading
            as="h4"
            fontWeight="semibold"
            mb="25px"
            mt="15px"
            textAlign="center"
            variant="h3"
          >
            <Text display="inline">{`${company}`} </Text>
            is sorry to see you go! <br />
            <br />
            <Text fontSize="md" color="grey">
              Are you sure you would still like to unsubscribe from onboarding emails?
            </Text>
          </Heading>
          <Form
            url={'/unsubscribe'}
            method={'PATCH'}
            data={{
              signupId: {
                type: 'hidden',
                value: signupId,
              },
              email: {
                type: 'hidden',
                value: email,
              },
            }}
            buttonText={'Unsubscribe'}
            callback={() => {
              context.notification.show('You are succesfully unsubscribed', 'success', true);
              setTimeout(function () {
                history.push('/');
              }, 2500);
            }}
          />
        </Container>
      </>
    );
  } else {
    history.push('/');
    return null;
  }
}
