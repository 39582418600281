import client from '../client';

export const docFields = `
  'docID': _id,
  title,
  subtitle,
  'slug': slug.current,
`;

// gets titles and slugs for table on contents for documentation
// documentation titles will be set on table contents based on priority
export async function getAllDocs() {
  const results = await client.fetch(
    `*[_type == "documentation"] | order(priority asc) {${docFields}}`
  );
  return results;
}

export async function getDocsBySlug(slug) {
  const result = await client
    .fetch(
      `*[_type == "documentation" && slug.current == $slug] {
      ${docFields}
      body[]{..., "asset": asset->}
      }`,
      { slug }
    )
    .then((res) => res[0]);

  return result;
}
