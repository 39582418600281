import React from 'react';
import { Bar } from 'react-chartjs-2';

export function BarChart(props) {
  let options = { ...props.options };
  options.maintainAspectRatio = false;
  options.responsive = true;

  return <Bar data={props.data} options={options} />;
}
