/***
 *
 *   FORGOT PASSWORD
 *   Trigger a password reset process
 *
 **********/

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Heading, Link, Text } from '@chakra-ui/react';
import { Form } from 'components/lib';

export function ForgotPassword() {
  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        Reset Password
      </Heading>
      <Container mt="60px" variant="formCard">
        <Form
          data={{
            username: {
              label: 'Username',
              value: '',
              type: 'text',
              autocomplete: 'off',
              tabindex: '-1',
            },
            email: {
              label: 'Email Address',
              type: 'email',
              required: true,
              maxLength: 64,
            },
          }}
          url="/user/password/reset/request"
          method="POST"
          buttonText="Reset Password"
        />
        <Text textStyle="formText" mt="10px">
          <Link as={RouterLink} to="/signin" color="orange">
            Click here
          </Link>{' '}
          to log in.
        </Text>
      </Container>
    </>
  );
}
