/***
 *
 *   SETUP LAYOUT
 *   You can delete this layout after completing the setup process
 *
 **********/

import React, { useEffect } from 'react';
import { Logo } from 'components/lib';
import './setup.scss';

export function SetupLayout(props) {
  useEffect(() => {
    document.body.classList.add('color');

    // cleanup
    return () => {
      document.body.classList.remove('color');
    };
  }, []);

  return (
    <main className="setup">
      <Logo />
      {<props.children {...props.data} />}
    </main>
  );
}
