import { Blog } from 'views/blog/index';
import { BlogPost } from 'views/blog/post';

const blog = [
  {
    path: '/blog',
    view: Blog,
    layout: 'page',
    title: 'OnboardList Blog',
  },
  {
    path: '/blog/:slug',
    view: BlogPost,
    layout: 'page',
    title: 'OnboardList Blog',
  },
];
export default blog;
