/***
 *
 *   BLOG
 *   Blog page with all blog items
 *
 **********/

import React, { useState, useEffect } from 'react';
import { Heading, SimpleGrid } from '@chakra-ui/react';
import { BlogItem } from 'components/blog/item';
import { getAllBlogs } from 'sanity/apis/blog';

export function Blog() {
  const [allBlogs, setAllBlogs] = useState(null);

  useEffect(() => {
    getAllBlogs().then((data) => setAllBlogs(data));
  }, []);

  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        OnboardList Blog
      </Heading>
      <Heading as="h5" mb="125px" mt="25px" textAlign="center" variant="h5">
        Reading the latest news about startup life, SaaS, onboarding, and what&apos;s happening at
        OnboardList!
      </Heading>
      <SimpleGrid
        as="section"
        columns={{ base: 1, md: 2 }}
        justifyItems="center"
        m="0 auto"
        maxW="768px"
      >
        {allBlogs &&
          allBlogs.map((blog) => (
            <BlogItem
              key={blog.blogID}
              title={blog.title}
              subtitle={blog.subtitle}
              slug={blog.slug}
              author={blog.author}
              mainImage={blog.mainImage}
              categories={blog.categories}
              publishedAt={blog.publishedAt}
              body={blog.body}
            />
          ))}
      </SimpleGrid>
    </>
  );
}
