/***
 *
 *   STEPS NAV
 *   Navigate through managing steps
 *
 **********/

import React from 'react';
import { Box, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';
import './sub.scss';

export function StepsNav(props) {
  return (
    <Box as="nav" bgColor="white" padding="20px 0px 20px 26px" m="-35px -24px 50px -25px">
      <Link
        as={NavLink}
        exact
        to={`/dashboard/checklist/${props.checklistId}/steps`}
        variant="subNavLink"
      >
        Steps
      </Link>
      <Link
        as={NavLink}
        exact
        to={`/dashboard/checklist/${props.checklistId}/settings`}
        variant="subNavLink"
      >
        Settings
      </Link>
    </Box>
  );
}
