/***
 *
 *   GRID
 *   Responsive grid layout
 *
 *   PROPS
 *   className: pass a custom class object
 *
 **********/

import React from 'react';
import ClassNames from 'classnames';
import Style from './grid.module.scss';

export function Grid(props) {
  const css = ClassNames([Style.container, props.className]);

  return <div className={css}>{props.children}</div>;
}
