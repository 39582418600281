import { Signup } from 'views/auth/signup';
import { SignupUser } from 'views/auth/signupuser';
import { Signin } from 'views/auth/signin';
import { ForgotPassword } from 'views/auth/forgotpassword';
import { ResetPassword } from 'views/auth/resetpassword';

const auth = [
  {
    path: '/signup',
    view: Signup,
    layout: 'page',
    title: 'OnboardList Sign Up',
  },
  {
    path: '/signup/user',
    view: SignupUser,
    layout: 'page',
    title: 'OnboardList Sign Up',
  },
  {
    path: '/signin',
    view: Signin,
    layout: 'page',
    title: 'OnboardList Sign In',
  },
  {
    path: '/forgotpassword',
    view: ForgotPassword,
    layout: 'page',
    title: 'OnboardList Forgot Password',
  },
  {
    path: '/resetpassword',
    view: ResetPassword,
    layout: 'page',
    title: 'OnboardList Reset Password',
  },
];
export default auth;
