/***
 *
 *   Flex
 *   Responsive flex layout
 *
 *   PROPS
 *   className: pass a custom class object
 *
 **********/

import React from 'react';
import ClassNames from 'classnames';
import Style from './flex.module.scss';

export function Flex(props) {
  const css = ClassNames([
    Style.container,
    Style[props.direction],
    Style[props.align],
    props.className,
  ]);

  return <div className={css}>{props.children}</div>;
}
