/***
 *
 *   Reminders
 *   Enables an owner to see reminder emails and webhooks for them
 *
 **********/

import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ClassNames from 'classnames';
import { Button, Heading } from '@chakra-ui/react';
import { Card, useAPI, TitleRow, Table } from 'components/lib';
import Style from '../../components/button/button.module.scss';

export function Reminders() {
  const btnCss = ClassNames([Style.btn, Style.small]);

  // state
  const [remindersState, setReminders] = useState([]);
  const [checklistNames, setChecklistNames] = useState();
  const [checklist, setChecklist] = useState('');
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 10,
    totalPages: 0,
  });

  const history = useHistory();
  const reminders = useAPI(
    `/reminder?&page=${pagination.page}&limit=${pagination.limit}&search=${search}&checklist=${checklist}`
  );

  const viewDetails = (id) => {
    history.push(`/dashboard/reminders/${id}`);
  };

  const reminderData = remindersState?.map((reminder) => {
    return {
      id: reminder.id,
      to: reminder.to,
      date: reminder.date,
      checklist: reminder.checklist,
      step: reminder.step,
      opens: reminder.opens,
      clicks: reminder.clicks,
      view: (
        <Button
          variant="orange"
          onClick={() => viewDetails(reminder.id)}
          type="button"
          className={btnCss}
        >
          View Details
        </Button>
      ),
      reply_to: reminder.reply_to,
    };
  });

  const handleChecklist = (name, value) => {
    setChecklist(value);
  };

  const handlePageAmountDisplay = (name, value) => {
    setPagination((prevState) => {
      return { ...prevState, page: 0, limit: Number(value) };
    });
  };

  useEffect(() => {
    if (reminders.data) {
      setReminders(reminders.data.remindersData);
      setPagination({
        page: pagination.page,
        limit: pagination.limit,
        totalPages: Math.ceil(reminders.data.remindersCount / pagination.limit),
      });
      setChecklistNames(reminders.data.checklistNames);
    }
  }, [reminders.data, pagination.page, pagination.limit]);

  return (
    <>
      <TitleRow>
        <Heading variant="h5" color="orange">
          Manage Reminder Emails Sent
        </Heading>
      </TitleRow>
      <Card>
        <Table
          loading={reminders.loading}
          headerData={[
            {
              label: 'Checklists',
              onChange: handleChecklist,
              text: 'checklist-select',
              name: 'checklist-select',
              default: 'View All',
              options: checklistNames,
            },
            {
              label: 'Per Page',
              onChange: handlePageAmountDisplay,
              text: 'limit',
              name: 'limit',
              default: pagination.totalPages || 25,
              options: [
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 500, label: 500 },
                { value: 1000, label: 1000 },
              ],
            },
          ]}
          tableData={reminderData}
          tableColumns={[
            {
              Header: 'To',
              accessor: 'to',
            },
            {
              Header: 'Checklist',
              accessor: 'checklist',
            },
            {
              Header: 'Step',
              accessor: 'step',
            },
            {
              Header: 'Date',
              accessor: 'date',
            },
            {
              Header: 'Opens',
              accessor: 'opens',
            },
            {
              Header: 'Clicks',
              accessor: 'clicks',
            },
            {
              Header: ' ',
              accessor: 'view',
            },
          ]}
          pagination={pagination}
          setPagination={setPagination}
          filter={search}
          setFilter={setSearch}
        />
      </Card>
    </>
  );
}
