/***
 *
 *   LOADER
 *   Infinite spinning animation for loading states
 *
 **********/

import React from 'react';
import ClassNames from 'classnames';
import Loading from './images/loading.gif';
import Style from './loader.module.scss';

export function Loader(props) {
  const css = ClassNames([Style.loader, props.className]);

  return (
    <div className={css}>
      <img src={Loading} alt="Loading Icon" />
    </div>
  );
}
