/***
 *
 *   ADMIN ANALYTICS
 *   Overview of your application usage
 *
 **********/

import React from 'react';
import { Flex } from '@chakra-ui/react';
import { Card, Chart, Stat, useAPI } from 'components/lib';

export function Admin(props) {
  const stats = useAPI('/metrics/accounts');
  const growth = useAPI('/metrics/accounts/growth');

  return (
    <>
      {/* 99% value is applied here so the side app navigation won't get squeezed on window resize. 
        With this value, breakpoint is respected */}
      <Flex direction="column" width="99%">
        <Stat
          loading={stats?.loading}
          value={stats?.data?.totalAccounts}
          label="Accounts"
          icon="users"
        />
        <Stat
          loading={stats?.loading}
          value={stats?.data?.activeAccounts}
          label="Active"
          icon="check"
        />
        <Stat
          loading={stats?.loading}
          value={stats?.data?.churnedAccounts}
          label="Churned"
          icon="arrow-down-right"
        />
        <Card title="User Growth">
          <Chart type="line" color="green" data={growth.data} loading={growth.loading} />
        </Card>
      </Flex>
    </>
  );
}
