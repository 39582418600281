/***
 *
 *   Signups
 *   Enables an owner to manage their signups for each checklist
 *
 **********/

import React, { useState, useContext, useEffect } from 'react';
import { Card, useAPI, ViewContext, Table } from 'components/lib';
import { NavLink } from 'react-router-dom';

export function Signup(props) {
  const context = useContext(ViewContext);
  // Uses History.state to pass variable from dashboard for View Signups Button
  const [checklist, setChecklist] = useState(props.location.state?.checklist || '');

  // State
  const [signupList, setSignupList] = useState([]);
  const [checklistNames, setChecklistNames] = useState();
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 25,
    totalPages: 0,
  });

  const signups = useAPI(
    `/signup?page=${pagination.page}&limit=${pagination.limit}&search=${search}&checklist=${checklist}`
  );

  useEffect(() => {
    if (signups.data) {
      setSignupList(signups.data.signupsList);
      setPagination({
        page: pagination.page,
        limit: pagination.limit,
        totalPages: Math.ceil(signups.data.signupsCount / pagination.limit),
      });
      setChecklistNames(signups.data.checklistNames);
    }
  }, [signups.data, pagination.page, pagination.limit]);

  const handleChecklist = (name, value) => {
    setChecklist(value);
  };

  const handlePageAmountDisplay = (name, value) => {
    setPagination((prevState) => {
      return { ...prevState, page: 0, limit: Number(value) };
    });
  };

  const editSignup = (data, callback) => {
    context.modal.show(
      {
        title: `Edit Signup`,
        form: {
          checklistId: {
            type: 'hidden',
            value: data.checklistId,
          },
          subscribed: {
            label: 'Subscribed',
            type: 'switch',
            value: data.subscribed,
            default: data.subscribed,
          },
        },
        buttonText: `Save`,
        url: `/signup/${data.id}`,
        method: 'PATCH',
      },
      (res) => {
        if (res.data) {
          const updatedSignupLists = signupList?.map((signup) => {
            if (signup.id === data.id) {
              return res.data.data;
            } else return signup;
          });
          setSignupList(updatedSignupLists);
        }
        context.notification.show(data.email + ' was Updated', 'success', true);
      }
    );
  };

  const deleteSignup = (data, callback) => {
    context.modal.show(
      {
        form: {
          checklistId: {
            type: 'hidden',
            value: data.checklistId,
          },
        },
        title: 'Delete Signup',
        buttonText: 'Delete Signup',
        text: 'Do you want to delete signup ' + data.email + '?',
        url: `/signup/${data.id}`,
        method: 'DELETE',
      },
      (res) => {
        if (res.data) {
          const updatedSignupLists = signupList?.filter((signup) => signup.id !== data.id);
          setSignupList(updatedSignupLists);
        }
        context.notification.show(data.email + ' was deleted', 'success', true);
      }
    );
  };

  const resetChecklist = (data) => {
    context.modal.show(
      {
        form: {
          checklistId: {
            type: 'hidden',
            value: data.checklistId,
          },
        },
        title: 'Reset Checklist',
        buttonText: 'Reset',
        text:
          'Are you sure you want to uncheck all the steps of the Checklist associated with this Signup?',
        url: `/signup/${data.id}/reset`,
        method: 'DELETE',
      },
      (res) => {
        context.notification.show(data.email + ' was deleted', 'success', true);
      }
    );
  };

  const signupsData = signupList?.map((signup) => {
    return {
      id: signup.id,
      name: `${signup.first_name}  ${signup.last_name}`,
      checklistName: (
        <NavLink to={`/dashboard/checklist/${signup.checklist_id}/steps`}>
          {signup.checklist_name}
        </NavLink>
      ),
      email: signup.email,
      subscribed: signup.subscribed,
      date: signup.date_created,
      checklistId: signup.checklist_id,
      actions: {
        custom: [{ icon: 'refresh-cw', action: resetChecklist, title: 'Reset Checklist' }],
        edit: editSignup,
        delete: deleteSignup,
        email: true,
      },
    };
  });

  return (
    <>
      <Card className="signups-card">
        <Table
          loading={signups.loading}
          headerData={[
            {
              label: 'Checklists',
              onChange: handleChecklist,
              text: 'checklist-select',
              name: 'checklist-select',
              default: pagination.totalPages || 25,
              options: checklistNames,
            },
            {
              label: 'Per Page',
              onChange: handlePageAmountDisplay,
              text: 'limit',
              name: 'limit',
              default: 'View All',
              options: [
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 500, label: 500 },
                { value: 1000, label: 1000 },
              ],
            },
          ]}
          tableData={signupsData}
          tableColumns={[
            {
              Header: 'Identifier',
              accessor: 'id',
            },
            {
              Header: 'Name',
              accessor: 'name',
            },
            {
              Header: 'Checklist Name',
              accessor: 'checklistName',
            },
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Subscribed',
              accessor: 'subscribed',
            },
            {
              Header: 'Signup Date',
              accessor: 'date',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
            },
          ]}
          pagination={pagination}
          setPagination={setPagination}
          filter={search}
          setFilter={setSearch}
        />
      </Card>
    </>
  );
}
