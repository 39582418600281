/***
 *
 *   SIGN UP
 *   Signup form for account owners
 *
 **********/

import React, { useContext } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { Container, Heading, Link, Text } from '@chakra-ui/react';
import { AuthContext, Form } from 'components/lib';
import { trialDays as defaultDays } from 'settings';

export function Signup() {
  const context = useContext(AuthContext);

  const query = new URLSearchParams(useLocation().search);

  const checkHex = new RegExp(/^[A-F0-9]+$/i);
  const trialDays = checkHex.test(query.get('trial')) ? parseInt(query.get('trial'), 16) : false;

  let error = '';
  if (query.get('trial') && !trialDays) {
    error = 'Something went wrong, Please click the link in your email invite';
  }

  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        Sign Up
      </Heading>
      <Container mt="60px" variant="formCard">
        <Heading as="h4" fontWeight="semibold" mb="25px" mt="15px" textAlign="center" variant="h5">
          Try OnboardList free for {trialDays || defaultDays} days!
        </Heading>
        <Form
          data={{
            username: {
              label: 'Username',
              value: '',
              type: 'text',
              autocomplete: 'off',
              tabindex: '-1',
            },
            email: {
              label: 'Email Address',
              value: '',
              type: 'email',
              required: true,
              maxLength: 64,
            },
            password: {
              label: 'Password',
              type: 'password',
              required: true,
              complexPassword: true,
              maxLength: 64,
            },
            confirm_password: {
              type: 'hidden',
              value: null,
            },
            trialDays: {
              type: 'hidden',
              value: trialDays,
            },
          }}
          url="/account"
          method="POST"
          buttonText="Start Free Trial"
          callback={context.signin}
        />

        <Text textStyle="formText" mt="10px">
          Already have an account?{' '}
          <Link as={RouterLink} to="/signin" color="orange">
            Log in.
          </Link>
        </Text>
        {error && <Text textStyle="errorText">{error}</Text>}
      </Container>
    </>
  );
}
