/***
 *
 *   PROGRESS BAR
 *   Percentage based progress bar with animated fill
 *
 *   PROPS
 *   label: text label (optional)
 *   progress: percentage value: eg. 75%
 *
 **********/

import React from 'react';
import { Box, Flex } from '@chakra-ui/react';

export function ProgressBar(props) {
  return (
    <Flex height="25px" color="darkGray" fontWeight="semibold">
      {props.label && (
        <Box width="50%" textAlign="right" mt="auto" mb="auto" mr="30px" fontSize="xs">
          {props.label}
        </Box>
      )}

      <Flex
        width="50%"
        mt="auto"
        mb="auto"
        height="16px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          width={props.progress}
          height="100%"
          transition="all 0.5s"
          bgColor="#EC4815"
          border="1px groove #d3d3d3"
        />
        <Box fontWeight="bold" bgColor="white" pl="10px">
          {props.progress}
        </Box>
      </Flex>
    </Flex>
  );
}
