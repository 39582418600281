/***
 *
 *   SIGN IN
 *   Sign in form for all account types (including admin).
 *
 **********/

import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Heading, Link, Text } from '@chakra-ui/react';
import { AuthContext, Form } from 'components/lib';

export function Signin() {
  const context = useContext(AuthContext);

  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        Sign In
      </Heading>
      <Container mt="60px" variant="formCard">
        <Form
          data={{
            username: {
              label: 'Username',
              value: '',
              type: 'text',
              autocomplete: 'off',
              tabindex: '-1',
            },
            email: {
              label: 'Email Address',
              type: 'email',
              required: true,
              maxLength: 64,
            },
            password: {
              label: 'Password',
              type: 'password',
              required: true,
              maxLength: 64,
            },
            forgotpassword: {
              type: 'link',
              url: '/forgotpassword',
            },
          }}
          url="/user/auth"
          method="POST"
          buttonText="Sign In"
          callback={context.signin}
        />
        <Text textStyle="formText" mt="10px">
          Forgot your password?{' '}
          <Link as={RouterLink} to="/forgotpassword" color="orange">
            Click here.
          </Link>
        </Text>
      </Container>
    </>
  );
}
