/***
 *
 *   APP NAV
 *   Primary navigation used inside the main app component
 *
 *   PROPS
 *   type: fixed or popup (style of nav on mobile)
 *   items: array of objects containing label, link and icon (optional).
 *          User permission level determines what links/labels are rendered
 *
 **********/

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Flex, Link } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

export function AppNav(props) {
  // state
  const [open, setOpen] = useState(false); // mobile is open
  const iconName = open ? 'times' : 'bars';

  return (
    <>
      <Box
        display={{ base: 'block', md: 'none' }}
        position="fixed"
        zIndex={5}
        color={open ? 'black' : 'white'}
        right={{ base: open ? '35px' : '40px' }}
        top={{ base: open ? '40px' : '25px' }}
        _hover={{ cursor: 'pointer' }}
      >
        <FontAwesomeIcon icon={iconName} onClick={(e) => setOpen(!open)} size="lg" />
      </Box>
      <Flex
        display={{ base: open ? 'flex' : 'none', md: 'flex' }}
        height={{ base: '420px', md: 'initial' }}
        position={{ base: 'fixed', md: 'initial' }}
        zIndex={{ base: 4, md: 0 }}
        width={{ base: '95%', md: '300px' }}
        bgColor="white"
        pt={{ base: '20px', md: '40px' }}
        // hides boxshadow on top
        overflowY="hidden"
        boxShadow={{ base: '0px 5px 5px lightGray', md: 'none' }}
        borderRadius={{ base: '5px', md: '0px' }}
        pr={{ base: 'initial' }}
        pl={{ base: 'initial' }}
        direction="column"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        alignItems="center"
      >
        {props.items?.map((item, index) => {
          return (
            <Link
              as={NavLink}
              key={item.label}
              color="darkGray"
              to={item.link}
              width={{ base: '200px', md: '100%' }}
              display="flex"
              fontSize="sm"
              fontWeight="semibold"
              _activeLink={{ bg: { base: 'white', md: 'lightGray' } }}
              _focus={{ outline: 'none', bg: 'lightGray' }}
              _hover={{ bgColor: { base: 'none', md: 'lightGray' }, cursor: 'pointer' }}
              height="70px"
              borderBottom={{ base: 'none', md: '2px solid #f3f3fa' }}
              justifyContent={{ base: 'center', md: 'flex-start' }}
              pl={{ base: 'initial', md: '40px' }}
              alignItems="center"
              exact
            >
              {item.label}
            </Link>
          );
        })}
      </Flex>
    </>
  );
}
