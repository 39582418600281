/***
 *
 *   HOMEPAGE
 *   Homepage template with features, latest blogs, testimonials and CTA
 *
 **********/

import React, { useEffect, useState } from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { getLatestBlogs } from 'sanity/apis/blog';
import { BlogItem, Hero } from 'components/lib';

export function Home() {
  const [latestBlogs, setLatestBlogs] = useState(null);

  useEffect(() => {
    getLatestBlogs().then((data) => setLatestBlogs(data));
  }, []);

  return (
    <>
      <Hero
        headline="The Onboarding CRM for SaaS"
        subHeadline="Increase your new user activation rate by guiding new users, monitoring their progress, and fixing roadblocks. Now in private beta!"
      />
      <Heading
        as="h2"
        mb={{ base: '50px' }}
        mt={{ base: '100px', sm: '50px' }}
        fontSize={{ base: 'lg', md: '2xl' }}
        variant="h2"
      >
        Read the latest from our blog:
      </Heading>
      <Flex as="section" direction={{ base: 'column', md: 'row' }} justify="center">
        {latestBlogs &&
          latestBlogs.map((blog) => (
            <BlogItem
              key={blog.blogID}
              title={blog.title}
              subtitle={blog.subtitle}
              slug={blog.slug}
              mainImage={blog.mainImage}
              publishedAt={blog.publishedAt}
            />
          ))}
      </Flex>
    </>
  );
}
