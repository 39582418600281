import { Signup } from 'views/checklist/signup';

const signup = [
  {
    path: '/dashboard/signups',
    view: Signup,
    layout: 'app',
    title: 'Signups',
    permission: 'user',
  },
];

export default signup;
