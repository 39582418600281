/***
 *
 *   HERO
 *   Hero section on landing page
 *
 *   PROPS
 *   headline: h1 title
 *   subHeadline: h5 tagline
 *   image: source object
 *   alt: img alt tag
 *
 **********/
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Flex, Heading, Image, Link } from '@chakra-ui/react';
import laptop from './images/hero-laptop.png';
import ipad from './images/hero-ipad.png';

export function Hero({ headline, subHeadline }) {
  return (
    <Flex
      as="section"
      align="center"
      direction={{ base: 'column' }}
      justify="center"
      mt="150px"
      ml="auto"
      mr="auto"
      maxW="1280px"
    >
      <Box textAlign="center">
        <Heading
          as="h1"
          mb={{ base: '25px', md: '20px' }}
          mr={{ base: '0px', md: '15px' }}
          fontSize={{ base: 'xl', sm: '3xl' }}
          variant="h1"
        >
          {headline}
        </Heading>
        <Heading
          as="h5"
          maxW="800px"
          mb={{ base: '25px', md: '40px' }}
          mr={{ base: '0px', md: '15px' }}
          variant="h5"
        >
          {subHeadline}
        </Heading>
        <Flex align="center" direction={{ base: 'column', md: 'row' }} justify="center">
          <Button
            as="button"
            mb={{ base: '40px', md: '75px' }}
            mr={{ base: '0px', md: '25px' }}
            width="185px"
            variant="orangeOutline"
            type="button"
            onClick={() => {
              window.Calendly.initPopupWidget({ url: 'https://calendly.com/onboardlist/demo' });
              return false;
            }}
          >
            Schedule Demo
          </Button>
          <Link
            as={RouterLink}
            to="/signup"
            fontSize={'20px'}
            backgroundColor="orange"
            color="white"
            borderColor="orange"
            borderRadius="5px"
            width="185px"
            mb={{ base: '40px', md: '75px' }}
            mr={{ base: '0px', md: '25px' }}
            pb={{ base: '10px', md: '10px' }}
            pl={{ base: '10px', md: '10px' }}
            pr={{ base: '10px', md: '10px' }}
            pt={{ base: '10px', md: '10px' }}
          >
            Start Free Trial
          </Link>
        </Flex>
      </Box>

      <Image alt="laptop" display={{ base: 'none', md: 'block' }} width="800px" src={laptop} />
      <Image alt="checklist" display={{ base: 'block', md: 'none' }} width="335px" src={ipad} />
    </Flex>
  );
}
