import { Reminders } from '../views/reminders/reminders';
import { ViewDetails } from '../views/reminders/viewdetails';

const reminders = [
  {
    path: '/dashboard/reminders',
    view: Reminders,
    layout: 'app',
    title: 'Reminders',
    permission: 'user',
  },
  {
    path: '/dashboard/reminders/:reminderId',
    view: ViewDetails,
    layout: 'app',
    title: 'Reminder Details',
    permission: 'user',
  },
];

export default reminders;
