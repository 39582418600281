import sanityClient from '@sanity/client';

const options = {
  projectId: process.env.REACT_APP_SANITY_PROJECT_ID, // find this at manage.sanity.io or in your sanity.json
  dataset: process.env.REACT_APP_SANITY_DATASET_NAME, // this is from those question during 'sanity init'
  useCdn: true,
  // useCdn === true, gives you fast response, it will get you
  // cached data
  // useCdn === false, give you little bit slower response, but
  // latest data
};

export default sanityClient(options);
