/***
 *
 *   BLOG POST
 *   Renders blog post according to slug
 *
 **********/

import React, { useState, useEffect } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Button, Container, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { Date, BlogBody } from 'components/lib';
import { getBlogBySlug } from 'sanity/apis/blog';
import { urlFor } from 'sanity/utils';

export function BlogPost() {
  const [postData, setPostData] = useState(null);
  const { slug } = useParams();

  useEffect(() => {
    getBlogBySlug(slug).then((data) => setPostData(data));
  }, [slug]);

  if (!postData)
    return (
      <Text
        fontSize={{ base: 'xl', md: '2xl' }}
        mt={{ base: '200px', md: '300px' }}
        textAlign="center"
      >
        Loading...
      </Text>
    );

  return (
    <>
      <Heading as="h1" mt="125px" variant="h1">
        OnboardList Blog
      </Heading>
      <Container as="section" variant="article">
        <Heading as="h5" variant="articleTitle">
          {postData.title}
        </Heading>
        <Container textAlign="center" mb="35px" mt="15px">
          <Date dateString={postData.publishedAt} />
        </Container>
        <BlogBody body={postData.body} />
        <Image
          alt="author"
          borderRadius="50%"
          mt={{ base: '40px', md: '50px' }}
          width="150px"
          ml="auto"
          mr="auto"
          src={urlFor(postData.author.picture).height(150).width(150).url()}
        />
        <Text
          fontSize="xs"
          fontWeight="bold"
          mb={{ base: '20px', md: '50px' }}
          mt="15px"
          textAlign="center"
        >
          by {postData.author.name}
        </Text>
      </Container>
      <Flex align="center" direction={{ base: 'column', md: 'row' }} mb="125px" justify="center">
        <Button
          as={RouterLink}
          type="button"
          to="/blog"
          mr={{ base: '0px', md: '20px' }}
          variant="seafoam"
        >
          View All Posts
        </Button>
        <Button
          className="manual-option-trigger signup"
          data-form-gx9xi5
          as="button"
          type="button"
          onClick={() => window['ml_webform_3138947']('show')}
          ml={{ base: '0px', md: '20px' }}
          mt={{ base: '30px', md: '0px' }}
          variant="orange"
        >
          Get Email Updates
        </Button>
      </Flex>
    </>
  );
}
