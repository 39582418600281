/***
 *
 *   BILLING
 *   Change a subscription plan or update card details
 *
 **********/

import React, { useContext } from 'react';
import {
  AuthContext,
  Card,
  AccountNav,
  TabView,
  Message,
  Form,
  PaymentForm,
  useAPI,
  usePlans,
} from 'components/lib';

const Messages = require('./messages.json');

export function Billing(props) {
  // state/context
  const context = useContext(AuthContext);

  // fetch plans and subscription
  const plans = usePlans();
  const subscription = useAPI('/account/subscription');
  const isPaid = context.user.plan === 'free' ? false : true;

  return (
    <>
      <AccountNav />
        {!subscription?.loading && subscription?.data !== 'active' && (
          <Message {...Messages[subscription.data]} />
        )}

        <TabView name="Billing" labels={['Plan', isPaid ? 'Card' : undefined]}>
          <Card loading={plans.loading} restrictWidth>
            <Form
              data={{
                plan: {
                  label: 'Your Subscription Plan',
                  type: 'select',
                  required: true,
                  default:
                    plans?.data?.active === 'free'
                      ? plans?.data?.list[3]?.value
                      : plans?.data?.active,
                  options: plans?.data?.list,
                },
              }}
              url="/account/plan"
              method="PATCH"
              buttonText="Update Plan"
              callback={(res) => {
                context.update({ plan: res.data.data.plan });
              }}
            />
          </Card>

          {isPaid && (
            <Card restrictWidth>
              <PaymentForm
                className="restrict-width"
                data={{
                  token: {
                    label: 'Update Your Card',
                    type: 'creditcard',
                    required: true,
                  },
                }}
                url="/account/card"
                method="PATCH"
                buttonText="Save Card"
              />
            </Card>
          )}
        </TabView>
    </>
  );
}
