/***
 *
 *   BlogItem
 *   Renders each blog item to select from for the blog's landing page
 *
 *   PROPS
 *   title: blog post's title
 *   subtitle: blog post's subtitle
 *   slug: unique string for url
 *   mainImage: blog post's main image, which appears under the blog post's heading
 *   publishedAt: publication date
 *
 **********/

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Button, Flex, Heading, Image, Link, Text } from '@chakra-ui/react';

import { Date } from 'components/lib';
import { urlFor } from 'sanity/utils';

// flex 1 is applied to content below to keep "Read More" button align to bottom of BlogItem (like footer)
export function BlogItem(props) {
  const { title, subtitle, slug, mainImage, publishedAt } = props;

  return (
    <Flex
      boxShadow="2xl"
      direction="column"
      height="550px"
      maxW="325px"
      mb={{ base: '75px', md: '150px' }}
      ml={{ base: 'auto', md: '30px' }}
      mr={{ base: 'auto', md: '30px' }}
      p="30px"
    >
      <Box flex="1">
        <Link as={RouterLink} to={`/blog/${slug}`}>
          <Image
            alt="blog image"
            _hover={{ cursor: 'pointer' }}
            mb="20px"
            ml="auto"
            mr="auto"
            src={urlFor(mainImage).width(265).height(175).url()}
          />
        </Link>
        <Heading as="h5" color="orange" mb="12px" variant="h5">
          <Link as={RouterLink} to={`/blog/${slug}`}>
            {title}
          </Link>
        </Heading>
        <Date dateString={publishedAt} />
        <Text fontSize="sm" fontWeight="normal" mt="20px">
          {subtitle}
        </Text>
      </Box>
      <Box textAlign="center">
        <Button as={RouterLink} to={`/blog/${slug}`} variant="seafoam">
          Read More
        </Button>
      </Box>
    </Flex>
  );
}
