/***
 *
 *   HOME NAV
 *   Navigation used on the main external website. Renders a dashboard link
 *   if the user is signed in, or a sign up link if they are not
 *
 **********/

import React, { useState, useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Flex, Link } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthContext, Logo } from 'components/lib';

export function HomeNav() {
  // context
  const context = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const handleToggle = () => setShow(!show);
  const iconName = show ? 'times' : 'bars';

  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      pt="24px"
      pb="24px"
      width="100%"
      wrap="wrap"
    >
      <Logo />
      <Box
        color={{ base: show ? 'white' : 'orange' }}
        display={{ base: 'block', md: 'none' }}
        pr={{ base: show ? '20px' : 'initial' }}
        position={{ base: show ? 'fixed' : 'initial', md: 'initial' }}
        right="0"
        zIndex={2}
      >
        <FontAwesomeIcon icon={iconName} onClick={handleToggle} size="lg" />
      </Box>
      <Flex
        alignSelf={{ md: 'flex-end' }}
        bg={{ base: 'orange', md: 'none' }}
        color={{ base: 'white', md: 'orange' }}
        direction={{ base: 'column', md: 'row' }}
        display={{ base: show ? 'flex' : 'none', md: 'flex' }}
        fontWeight="semibold"
        fontSize="md"
        justify={{ md: 'flex-end' }}
        left="0"
        pb={{ base: '50px', md: 'initial' }}
        pt={{ base: '100px', md: 'initial' }}
        position={{ base: 'fixed', md: 'initial' }}
        right="0"
        textAlign={{ base: 'center' }}
        top="0"
        width={{ base: '100%', md: 'auto' }}
        zIndex={1}
      >
        <Link
          as={RouterLink}
          to="/blog"
          mt={{ base: '15px', md: 'initial' }}
          mr={{ base: '0px', md: '50px' }}
          pt={{ base: '0px', md: '4px' }}
          _focus={{ outline: 'none' }}
        >
          Blog
        </Link>
        <Link
          as={RouterLink}
          to="/docs"
          mr={{ base: '0px', md: '50px' }}
          pt={{ base: '0px', md: '4px' }}
          _focus={{ outline: 'none' }}
        >
          Docs
        </Link>

        {context.user?.token ? (
          <Link
            as={RouterLink}
            to={context.user.permission === 'admin' ? '/admin' : '/dashboard'}
            mr={{ base: '0px', md: '50px' }}
            pt={{ base: '0px', md: '4px' }}
            _focus={{ outline: 'none' }}
          >
            Dashboard
          </Link>
        ) : (
          <>
            <Link
              as={RouterLink}
              to="/signin"
              border={{ base: 'none', md: '2px' }}
              borderColor="orange"
              borderRadius="5px"
              mr={{ base: '0px', md: '50px' }}
              pb={{ base: '0px', md: '2px' }}
              pl={{ base: '0px', md: '10px' }}
              pr={{ base: '0px', md: '10px' }}
              pt={{ base: '0px', md: '2px' }}
            >
              Sign In
            </Link>
            <Link
              as={RouterLink}
              to="/signup"
              backgroundColor="orange"
              color={{ base: 'white' }}
              borderColor="orange"
              borderRadius="5px"
              mr={{ base: '0px', md: '50px' }}
              pb={{ base: '0px', md: '2px' }}
              pl={{ base: '0px', md: '10px' }}
              pr={{ base: '0px', md: '10px' }}
              pt={{ base: '0px', md: '2px' }}
            >
              Sign Up
            </Link>
          </>
        )}
      </Flex>
    </Flex>
  );
}
