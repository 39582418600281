import React from 'react';
import { Box } from '@chakra-ui/react';
import { Message } from 'components/lib';

export function NotFound(props) {
  return (
    <Box maxW="500px" m="180px auto">
      <Message
        type="error"
        title="404"
        text="Oops, the page you're looking for doesn't exist."
        buttonText="Go Home"
        buttonLink="/"
      />
    </Box>
  );
}
