import React from 'react';
import { FormControl, FormErrorMessage, FormLabel, Select as ChakraSelect } from '@chakra-ui/react';

export function Select(props) {
  let options = props.options;
  const error = props.errorMessage || 'Please select an option';

  // set the default
  if (!props.default && options?.length) {
    // if theres no default, show a please select option
    if (options && options[0]?.value === 'unselected') options.shift(0);
    options.unshift({ value: 'unselected', label: 'Please select an option' });
  }

  function change(e) {
    let value = e ? e.target?.value : 'unselected';
    let valid = undefined;

    // validate
    valid = props.required && value === 'unselected' ? false : true;

    props.onChange(props.name, value, valid);
    props.callback && props.callback(value);
  }

  return (
    <FormControl
      id={props.name}
      isRequired={props.required}
      htmlFor={props.name}
      isInvalid={props.valid === false}
    >
      <FormLabel fontSize="sm" mb="15px" mt="15px">
        {props.label}
      </FormLabel>
      <ChakraSelect
        defaultValue={props.default}
        onChange={(e) => change(e)}
        id={props.name}
        fontSize="sm"
        height="40px"
        focusBorderColor="none"
        errorBorderColor="lightRed"
      >
        {options?.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </ChakraSelect>
      <FormErrorMessage
        bg="lightRed"
        color="white"
        fontSize="xxs"
        p="10px 16px"
        position="relative"
        top="-10px"
        outline="1px solid #d95565"
      >
        {error}
      </FormErrorMessage>
    </FormControl>
  );
}
