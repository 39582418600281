import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { Label, Error, Icon } from 'components/lib';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import Style from './richtextEditor.module.scss';
import draftToHtml from 'draftjs-to-html';
import ClassNames from 'classnames';
import htmlToDraft from 'html-to-draftjs';

const RichTextEditor = ({
  value,
  name,
  onChange,
  label,
  required,
  errorMessage,
  className,
  maxLength,
  isValid,
}) => {
  // Convert html from database to draft for defaultValue
  const blocksFromHTML = htmlToDraft(value);

  const state = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(() => EditorState.createWithContent(state));

  const [valid, setValid] = useState();

  const error = errorMessage || `Please enter a value (${maxLength} Limit)`;

  function convertStateToHtml() {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(rawContentState);
  }

  const handleBeforeInput = (input) => {
    if (maxLength) {
      if (draftToHtml(convertToRaw(editorState.getCurrentContent())).length >= maxLength) {
        return 'handled';
      }
    }
  };

  useEffect(() => {
    setValid(isValid);
  }, [isValid]);

  function validate(e) {
    const value = convertStateToHtml();
    let isValid;

    // input is required and value is blank
    required && value.length < 9 ? (isValid = false) : (isValid = true);

    // validate if maxLength is exceeded
    if (maxLength && value.length > maxLength) isValid = false;

    // input isn't required and value is blank
    if (!required) isValid = true;

    setValid(isValid);

    // update the parent form
    onChange(name, value, isValid);
  }

  // style
  const css = ClassNames([
    Style.textbox,
    className,
    valid && Style.success,
    valid === false && Style.error,
  ]);

  return (
    <div className={Style.richTextEditor}>
      <Label text={label} required={required} for={name} />
      <Editor
        onChange={(e) => onChange(name, convertStateToHtml(), valid)}
        onBlur={(e) => validate(e)}
        editorState={editorState}
        onEditorStateChange={setEditorState}
        wreditorerClassName={Style.wrapperClass}
        editorClassName={(Style.editorClass, css)}
        toolbarClassName={Style.toolbarClass}
        spellCheck={true}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'fontSize',
            'fontFamily',
            'list',
            'textAlign',
            'colorPicker',
            'link',
            'emoji',
            'image',
            'remove',
            'history',
          ],
          fontFamily: {
            options: [
              'Verdana',
              'Arial',
              'Georgia',
              'Tahoma',
              'Times New Roman',
              'Courier New',
              'Helvetica',
            ],
          },
        }}
        handleBeforeInput={handleBeforeInput}
      />
      {valid === false && <Error message={error} />}

      {valid && <Icon image="check" color="#8CC57D" className={Style.SuccessIcon} size={16} />}
    </div>
  );
};

export default RichTextEditor;
