/***
 *
 *  Manage Checklist Settings
 *  Enables an owner to manage the settings of a Checklist
 *
 **********/

import React, { useState, useEffect, useContext } from 'react';
import { Spinner, Heading } from '@chakra-ui/react';
import { Card, StepsNav, Form, useAPI, AuthContext, TitleRow } from 'components/lib';

export function Settings(props) {
  const { checklistId } = props.computedMatch.params;
  const checklist = useAPI(`/checklist/${checklistId}`);
  const auth = useContext(AuthContext);
  const [checklistState, setChecklistState] = useState([]);

  useEffect(() => {
    if (!checklist.loading) setChecklistState(checklist.data);
  }, [checklist]);

  return (
    <>
      <StepsNav checklistId={checklistId} />
      {checklistState ? (
        <TitleRow>
          <Heading variant="h5" color="orange">
            Settings for {checklistState?.checklist_name}
          </Heading>
        </TitleRow>
      ) : (
        <Spinner size="xl" color={'#ec4815'} />
      )}

      {checklistState && (
        <Card restrictWidth>
          <Form
            buttonText="Save"
            url={`/checklist/${checklistId}`}
            method="PATCH"
            data={{
              name: {
                label: 'Checklist Name',
                type: 'text',
                value: checklistState?.checklist_name,
                autocomplete: 'off',
                required: true,
                maxLength: 32,
              },
              headline: {
                label: 'Headline',
                type: 'text',
                required: true,
                value: checklistState?.headline || 'Welcome!',
                errorMessage: 'Please enter a Headline ',
                maxLength: 32,
              },
              message: {
                label: 'Message ',
                type: 'text',
                required: true,
                value:
                  checklistState?.message ||
                  'To get started, please complete and check off each task in the list below.',
                errorMessage: 'Please enter a Message',
                maxLength: 128,
              },
              color: {
                label: 'Hex Color (for checklist widget theme and reminder email CTA buttons)',
                type: 'text',
                required: true,
                value: checklistState?.color,
                placeholder: '#d84114',
                errorMessage: 'Please enter a color ', // TODO Set hex color check or rgb
                maxLength: 7,
                hexcolor: true,
              },
              font: {
                label: 'Font Family (must be available where you install the checklist widget)',
                type: 'text',
                required: true,
                value:
                  checklistState?.font ||
                  "'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif",
                errorMessage: 'Please enter a Font Family',
                maxLength: 128,
              },
              fromName: {
                label: 'Reminder Email From Name',
                type: 'text',
                value: auth.user.companyName,
                required: true,
                maxLength: 64,
              },
              replyEmail: {
                label: 'Reminder Email Reply-To Email Address',
                type: 'email',
                value: auth.user.email,
                required: true,
                maxLength: 64,
              },
              reminderInterval: {
                label: 'Reminder Email Interval (Days)',
                type: 'number',
                value: checklistState?.reminder_interval,
                autocomplete: 'off',
                required: true,
                min: 0,
              },
              hidePaths: {
                label: 'Visibility',
                type: 'radio',
                options: ['Always show', 'Hide on specific pages', 'Only show on specific pages'],
                default:
                  checklistState?.hide_paths === true
                    ? 'Hide on specific pages'
                    : checklistState?.hide_paths === false
                    ? 'Only show on specific pages'
                    : 'Always show',
                dynamicallyUpdate: {
                  inputIds: ['paths'], // choose what inputs need to change when this input gets updated
                },
              },
              paths: {
                label: 'Specify Paths',
                type: 'textarea',
                placeholder: 'List the URL of each page separated by a coma',
                value: checklistState?.paths?.join(),
              },
              strictEquality: {
                label: 'Apply strict equality',
                type: 'switch',
                default: false,
                value: checklistState?.strict_equality,
              },
              hideDesktop: {
                label: 'Hide on Desktop',
                type: 'switch',
                default: false,
                value: checklistState?.hide_desktop,
              },
              hideMobile: {
                label: 'Hide on Mobile',
                type: 'switch',
                default: false,
                value: checklistState?.hide_mobile,
              },
              hideTablet: {
                label: 'Hide on Tablet',
                type: 'switch',
                default: false,
                value: checklistState?.hide_tablet,
              },
            }}
          />
        </Card>
      )}
    </>
  );
}
