/***
 *
 *   DASHBOARD
 *
 *
 *
 **********/

import React from 'react';

import { Grid, useAPI, Stats } from 'components/lib';

export function Dashboard(props) {
  const checklists = useAPI('/checklist');

  return (
    <>
      {checklists?.data?.map((checklist, index) => {
        return (
          <Grid key={checklist.id} cols={index}>
            <Stats data={checklist} />
          </Grid>
        );
      })}
    </>
  );
}
