/***
 *
 *   VIEW
 *   The view houses global components that are common to all views
 *   (notification, modal), handles errors and renders the layout
 *
 **********/

import React, { useState } from 'react';
import {
  AppLayout,
  AuthLayout,
  HomeLayout,
  PageLayout,
  AdminLayout,
  SetupLayout,
  Modal,
  Notification,
  History,
} from '../lib';

import './scss/normalize.scss';
import './scss/view.scss';
import './scss/typography.scss';

export const ViewContext = React.createContext();

export function View(props) {
  // state
  const [notification, setNotification] = useState({
    visible: 'hide',
    autoclose: true,
  });
  const [modal, setModal] = useState({});

  // layouts
  const layouts = {
    app: AppLayout,
    auth: AuthLayout,
    admin: AdminLayout,
    setup: SetupLayout,
    home: HomeLayout,
    page: PageLayout,
  };

  // set title & layout
  document.title = props.title;
  let Layout = props.layout ? layouts[props.layout] : AppLayout;

  if (!props.display) return false;

  function showNotification(text, type, autoclose, format, icon) {
    setNotification({
      text: text,
      type: type,
      show: true,
      format: format,
      icon: icon,
      autoclose: autoclose,
    });

    if (autoclose) setTimeout(hideNotification, 6000);
  }

  function hideNotification() {
    setNotification({
      text: '',
      type: '',
      show: false,
      format: null,
      icon: null,
      autoclose: true,
    });
  }

  function showModal(content, callback) {
    let data = { ...modal };

    if (content) {
      for (let key in content) data[key] = content[key];

      data.show = true;
      data.callback = callback;
    }

    setModal(data);
  }

  function hideModal(cancel) {
    // callback?
    if (!cancel && modal.callback) modal.callback(modal.form);

    // reset the modal
    setModal({
      title: null,
      text: null,
      buttonText: null,
      url: null,
      method: null,
      show: false,
    });
  }

  function handleError(err) {
    let message = 'There was a glitch in the matrix – please try again';

    if (err) {
      message = err.toString();

      if (err.response) {
        err.response.data?.message
          ? (message = err.response.data.message)
          : (message = err.response.data); // limiter does not send a data.message

        if (err.response.status) {
          if (err.response.status === 400 && err.response.data) {
            switch (err.response.data.type) {
              case 'closed': // account closed
                showNotification(message, 'error', true);
                showModal(
                  {
                    title: 'Re-Open Account',
                    form: {
                      reOpen: {
                        type: 'hidden',
                        value: true,
                      },
                      email: {
                        label: 'Email',
                        type: 'email',
                        value: '',
                        required: true,
                        maxLength: 64,
                      },
                      password: {
                        label: 'Password',
                        type: 'password',
                        required: true,
                        maxLength: 64,
                      },
                    },
                    buttonText: 'Re-Open',
                    text: 'Do you want to Re-Open your Account ?',
                    url: '/user/auth',
                    method: 'POST',
                  },
                  (res) => {
                    showNotification(res.message, 'success', false);
                  }
                );
                break;

              // limit for checklist reached and downgrading
              case 'upgrade':
              case 'checklist':
                showModal(
                  {
                    title: message,
                    form: {},
                    url: null,
                    text: null,
                    buttonText:
                      err.response.data.type === 'checklist' ? 'Go to Checklist?' : 'Upgrade',
                  },
                  () => {
                    History.push(
                      err.response.data.type === 'checklist'
                        ? '/dashboard/checklists'
                        : '/dashboard/account/upgrade?plan=' + err.response.data.data
                    );
                  }
                );
                break;

              default:
                showNotification(message, 'error', false);
                break;
            }
          } else {
            switch (err.response.status) {
              case 401:
                History.push('/signin');
                break;

              case 402: // payment required
                History.push('/dashboard/account/upgrade?plan=' + err.response.data.plan);
                break;

              case 404:
                History.push('/notfound');
                break;

              default:
                console.error(err);
                showNotification(message, 'error', false);
                break;
            }
          }
        }
      }
    }
  }

  const context = {
    notification: {
      show: showNotification,
      hide: hideNotification,
      data: notification,
    },
    modal: {
      show: showModal,
      hide: hideModal,
      data: modal,
    },

    handleError: handleError,
  };

  return (
    <ViewContext.Provider value={context}>
      {notification.show && <Notification {...notification} />}

      {modal.show && <Modal {...modal} />}

      <Layout title={props.title} data={props.data} path={props.path}>
        {props.display}
      </Layout>
    </ViewContext.Provider>
  );
}
