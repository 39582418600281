import React, { useState } from 'react';
import { FormLabel, FormControl, Input, FormErrorMessage, Box } from '@chakra-ui/react';
import { Icon } from 'components/lib';

export function PasswordInput(props) {
  const [error, setError] = useState(props.errorMessage || 'Please enter a password');
  const [password, setPassword] = useState('');

  function validate(e) {
    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '') valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '') valid = true;

    if (props.required && value !== '') valid = true;

    if (!props.required) valid = true;

    // complexity rules
    if (props.complexPassword) {
      if (value.length < 8) {
        valid = false;
        setError('Password must be a minimum of 8 characters');
      } else if (!/[A-Z]/.test(value)) {
        valid = false;
        setError('Password must have at least one upper case letter');
      } else if (!/[a-z]/.test(value)) {
        valid = false;
        setError('Password must have at least one lower case letter');
      }
    }

    // update the parent form
    props.onChange(props.name, value, valid);
  }

  const confirmPassword = (e) => {
    let value = e ? e.target.value : '';
    let valid;

    if (props.confirmPassword) {
      if (password !== value) {
        valid = false;
        setError('Password does not match');
      }
      if (password === value) valid = true;
    }

    // update the parent form
    props.onChange(props.name, value, valid);
  };

  return (
    <FormControl isInvalid={props.valid === false} isRequired={props.required}>
      <FormLabel required={props.required} htmlFor={props.name} textStyle="formLabel">
        {props.label}
      </FormLabel>
      <Input
        maxLength={props.maxLength}
        type="password"
        id={props.name}
        fontSize="sm"
        name={props.name}
        onChange={(e) => {
          if (confirmPassword) setPassword(e.target.value);
          props.onChange(props.name, e.target.value, undefined);
        }}
        onBlur={(e) => validate(e)}
        size="lg"
        focusBorderColor="none"
        errorBorderColor="lightRed"
        borderLeftRadius="5px"
        borderRightRadius="5px"
        mb="20px"
        borderColor="black"
        variant="outline"
      />
      <FormErrorMessage
        bg="lightRed"
        color="white"
        fontSize="xxs"
        p="10px 16px"
        position="relative"
        top="-27px"
        outline="1px solid #d95565"
      >
        {error}
      </FormErrorMessage>
      {props.confirmPassword && (
        <FormControl isInvalid={props.valid === false}>
          <FormLabel required={props.required} htmlFor={'confirm password'} textStyle="formLabel">
            Confirm Password
          </FormLabel>
          <Input
            maxLength={props.maxLength}
            type={'password'}
            id={'confirm password'}
            fontSize="sm"
            name={props.name}
            onChange={(e) => confirmPassword(e)}
            size="lg"
            focusBorderColor="none"
            errorBorderColor="lightRed"
            borderLeftRadius="5px"
            borderRightRadius="5px"
            mb="20px"
            borderColor="black"
            variant="outline"
          />
        </FormControl>
      )}

      {props.valid === true && (
        <Box position="absolute" top="45%" right="10px">
          <Icon image="check" color="lightGreen" size={16} />
        </Box>
      )}
    </FormControl>
  );
}
