/***
 *
 *   HOME LAYOUT
 *   Main website layout
 *
 **********/

import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { HomeNav, Footer } from 'components/lib';

// Flex 1 rule is applied to keep footer at bottom.
export function HomeLayout(props) {
  return (
    <Box
      bgColor="seafoam"
      height={{ base: '900px', sm: '800px' }}
      left="0"
      position="absolute"
      top="0"
      width="100%"
      zIndex={1}
    >
      <Flex
        align="center"
        direction="column"
        flex="1"
        justify="center"
        maxW="1280px"
        pl="20px"
        pr="20px"
        ml="auto"
        mr="auto"
        minHeight="100vh"
      >
        <HomeNav />
        <Box as="main" flex="1">
          {<props.children {...props.data} />}
        </Box>
      </Flex>
      <Footer />
    </Box>
  );
}
