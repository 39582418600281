const features = [
  [
    { name: 'React UI', checked: true },
    { name: 'Authentication', checked: true },
    { name: 'REST API', checked: true },
    { name: 'Subscription Payments', checked: false },
    { name: 'Teams', checked: true },
    { name: 'Email Notifications', checked: true },
    { name: 'User Permissions', checked: true },
    { name: 'Admin', checked: false },
    { name: 'Unit Tests', checked: true },
    { name: 'Metrics', checked: false },
  ],
  [
    { name: 'React UI', checked: true },
    { name: 'Authentication', checked: true },
    { name: 'REST API', checked: true },
    { name: 'Subscription Payments', checked: true },
    { name: 'Teams', checked: true },
    { name: 'Email Notifications', checked: true },
    { name: 'User Permissions', checked: true },
    { name: 'Admin', checked: true },
    { name: 'Unit Tests', checked: true },
    { name: 'Metrics', checked: true },
  ],
];

export default features;
