import React from 'react';
import { Icon } from 'components/lib';
import { Box, FormLabel, FormControl, Input, FormErrorMessage } from '@chakra-ui/react';

export function EmailInput(props) {
  let error = props.errorMessage || 'Please enter a valid email address';

  function validate(e) {
    let value = e ? e.target.value : '';
    let valid = undefined;

    // input is required and value is blank
    if (props.required && value === '') valid = false;

    // input isn't required and value is blank
    if (!props.required && value === '') valid = true;

    // now test for a valid email
    const rex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    valid = rex.test(value);

    // update the parent form
    props.onChange(props.name, value, valid);
  }

  return (
    <FormControl isInvalid={props.valid === false} isRequired={props.required}>
      <FormLabel required={props.required} htmlFor={props.name} textStyle="formLabel">
        {props.label}
      </FormLabel>
      <Input
        maxLength={props.maxLength}
        type={props.type}
        id={props.name}
        fontSize="sm"
        name={props.name}
        value={props.value || ''}
        placeholder={props.placeholder}
        onChange={(e) => props.onChange(props.name, e.target.value, undefined)}
        onBlur={(e) => validate(e)}
        size="lg"
        focusBorderColor="none"
        errorBorderColor="lightRed"
        borderLeftRadius="5px"
        borderRightRadius="5px"
        mb="20px"
        borderColor="black"
        variant="outline"
      />
      <FormErrorMessage
        bg="lightRed"
        color="white"
        fontSize="xxs"
        p="10px 16px"
        position="relative"
        top="-27px"
        outline="1px solid #d95565"
      >
        {error}
      </FormErrorMessage>

      {props.valid === true && (
        <Box position="absolute" top="45%" right="10px">
          <Icon image="check" color="lightGreen" size={16} />
        </Box>
      )}
    </FormControl>
  );
}
