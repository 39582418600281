/***
 *
 *   Exclusions
 *   Enables an owner to manage emails they wish to exclude from reminders
 *
 **********/

import React, { useState, useContext, useEffect } from 'react';
import { Button, Heading } from '@chakra-ui/react';
import { Card, useAPI, ViewContext, TitleRow, Table } from 'components/lib';

export function Exclusions() {
  const context = useContext(ViewContext);

  //state
  const [exclusionsState, setExclusions] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 25,
    totalPages: 0,
  });

  const exclusions = useAPI(
    `/exclusions?&page=${pagination.page}&limit=${pagination.limit}&search=${search}`
  );

  useEffect(() => {
    if (exclusions.data) {
      setExclusions(exclusions.data.exclusionsData);
      setPagination({
        page: pagination.page,
        limit: pagination.limit,
        totalPages: Math.ceil(exclusions.data.exclusionsCount / pagination.limit),
      });
    }
  }, [exclusions.data, pagination.page, pagination.limit]);

  const addExclusions = () => {
    context.modal.show(
      {
        title: 'Add Emails to Exclude',
        form: {
          emails: {
            label: 'Emails to Exclude',
            type: 'textarea',
            value: '',
            placeholder:
              'user1@email.com user2@email.com user3@email.com user4@email.com \n \n user1@email.com, user2@email.com, user3@email.com, user4@email.com \n  user1@email.com%%user2@email.com#!user3@email.com,!!user4@email.com',
            autocomplete: 'off',
            required: true,
          },
        },
        buttonText: 'Add Emails',
        text: 'Do you want to exclude these emails from receiving reminder emails?',
        url: '/exclusions',
        method: 'POST',
      },
      (res) => {
        if (res.data) {
          const newExclusions = [...exclusionsState, ...res.data.data].splice(0, pagination.limit);

          setPagination({
            page: pagination.page,
            limit: pagination.limit,
            totalPages: Math.ceil(
              (res.data.data.length + exclusionsState.length) / pagination.limit
            ),
          });

          setExclusions(newExclusions);
        }
      }
    );
  };

  const handlePageAmountDisplay = (name, value) => {
    setPagination((prevState) => {
      return { ...prevState, page: 0, limit: Number(value) };
    });
  };

  const deleteExclusion = (data, callback) => {
    context.modal.show(
      {
        form: {
          exclusionId: {
            type: 'hidden',
            value: data.id,
          },
        },
        title: 'Delete Email',
        buttonText: 'Delete Email',
        text: 'Do you want to delete email ' + data.email + '?',
        url: `/exclusions`,
        method: 'DELETE',
      },
      (res) => {
        if (res.data) {
          const updatedExclusions = exclusionsState?.filter(
            (exclusion) => exclusion.id !== data.id
          );
          setExclusions(updatedExclusions);
        }
        context.notification.show(data.email + ' was deleted', 'success', true);
      }
    );
  };

  const exclusionsData = exclusionsState?.map((exclusion) => {
    return {
      id: exclusion.id,
      email: exclusion.email,
      date: exclusion.date_added,
      actions: {
        delete: deleteExclusion,
      },
    };
  });

  return (
    <>
      <TitleRow>
        <Heading variant="h5" color="orange">
          Manage Exclusions
        </Heading>
        <Button variant="titleRowButton" onClick={addExclusions}>
          Add Emails
        </Button>
      </TitleRow>
      <Card>
        {/* <Table
          exclusions={!!exclusionsState?.length}
          loading={exclusions.loading}
          className="restict-width"
          pagination={pagination}
          setPagination={setPagination}
          setSearch={setSearch}
          handleLimit={handleLimit}
          show={['email', 'date_added']}
          data={exclusionsState}
          actions={{
            delete: deleteExclusion,
          }}
        /> */}

        <Table
          loading={exclusions.loading}
          headerData={[
            {
              label: 'Per Page',
              onChange: handlePageAmountDisplay,
              text: 'limit',
              name: 'limit',
              default: 'View All',
              options: [
                { value: 25, label: 25 },
                { value: 50, label: 50 },
                { value: 100, label: 100 },
                { value: 500, label: 500 },
                { value: 1000, label: 1000 },
              ],
            },
          ]}
          tableData={exclusionsData}
          tableColumns={[
            {
              Header: 'Email',
              accessor: 'email',
            },
            {
              Header: 'Date Added',
              accessor: 'date',
            },
            {
              Header: 'Actions',
              accessor: 'actions',
            },
          ]}
          pagination={pagination}
          setPagination={setPagination}
          filter={search}
          setFilter={setSearch}
        />
      </Card>
    </>
  );
}
