/***
 *
 *   ADMIN ACCOUNTS
 *   Manage all accounts signed up to your application
 *
 **********/

import React, { useContext, useState, useEffect } from 'react';
import { ViewContext, Card, Table, usePlans, useAPI } from 'components/lib';

export function AdminAccounts(props) {
  const context = useContext(ViewContext);
  const plans = usePlans();
  const accounts = useAPI('/admin/accounts');
  const [accountsState, setAccountsState] = useState([]);

  useEffect(() => {
    if (!accounts.loading) setAccountsState(accounts.data);
  }, [accounts]);

  function editAccount(data, callback) {
    context.modal.show(
      {
        title: 'Edit Account',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
          email: {
            label: 'Email',
            type: 'email',
            required: true,
            value: data.email,
            maxLength: 64,
          },
          plan: {
            label: 'Plan',
            type: 'select',
            options: plans.data.list,
            default: data.plan,
            required: true,
          },
          active: {
            label: 'Status',
            type: 'select',
            default: data.active,
            options: [
              { value: 'yes', label: 'Active' },
              { value: 'no', label: 'Cancelled' },
            ],
            required: true,
          },
          date_deleted: {
            label: 'Re-Open Account',
            type: 'select',

            options: [{ value: null, label: 'Re-Open' }],
          },
        },
        buttonText: 'Save',
        url: '/admin/account',
        method: 'PATCH',
      },
      async (res) => {
        if (res.data?.data) {
          const accountsToUpdate = await accountsState[
            accountsState?.findIndex((account) => account.id === data.id)
          ];
          await Object.keys(res.data.data).map(
            (key) => (accountsToUpdate[key] = res.data.data[key])
          );
          const updatedAccounts = await accountsState?.filter((account) => account.id !== data.id);
          await updatedAccounts.push(accountsToUpdate);
          await setAccountsState(updatedAccounts);

          await context.notification.show(
            res.data.data.email || data.email + ' was updated',
            'success',
            true
          );
        }
      }
    );
  }

  function deleteAccount(data, callback) {
    context.modal.show(
      {
        title: 'Delete Account',
        form: {
          id: {
            type: 'hidden',
            value: data.id,
          },
        },
        buttonText: 'Delete Account',
        text:
          'Are you sure you want to delete ' + (data.name ? data.name : data.email) + "'s account?",
        url: '/admin/account',
        method: 'DELETE',
      },
      async (res) => {
        if (res.data) {
          const updatedAccounts = await accountsState?.filter((account) => account.id !== data.id);
          await setAccountsState(updatedAccounts);
        }
        await context.notification.show(
          res.data.data || data.email + ' was deleted',
          'success',
          true
        );
      }
    );
  }

  const accountsData = accountsState?.map((account) => {
    return {
      id: account.id,
      email: account.email,
      active: account.active,
      dateCreated: account.date_created,
      dateDeleted: account.date_deleted,
      plan: account.plan,
      actions: {
        edit: editAccount,
        delete: deleteAccount,
        email: true,
      },
    };
  });

  return (
    <Card loading={false}>
      <Table
        tableData={accountsData}
        loading={accounts.loading}
        tableColumns={[
          {
            Header: 'Email',
            accessor: 'email',
          },
          {
            Header: 'Active',
            accessor: 'active',
          },
          {
            Header: 'Date Created',
            accessor: 'dateCreated',
          },
          {
            Header: 'Date Deleted',
            accessor: 'dateDeleted',
          },
          {
            Header: 'Actions',
            accessor: 'actions',
          },
        ]}
      />
    </Card>
  );
}
